import React, { useEffect, useState, useRef } from "react";
import { API_HOST } from "../../../constants";
import axios from "axios";
import styled from "styled-components";
import he from "he";
import {
  Spinner,
  Pane,
  Tooltip,
  Position,
  Paragraph,
  Icon,
  SelectMenu,
  IconButton,
  SegmentedControl,
  Avatar,
  Button,
  TextInput
} from "evergreen-ui";
import * as dateFns from "date-fns";
import Helmet from "react-helmet";

import extractDomain from "extract-domain";

import { useFetchAPI } from "../../../helpers/data";
import { CSMS } from "../../../constants";

import { Row, Card, Header, Center, Main } from "../../../components/base";
// import PriorityScore from "../../../components/PriorityScore";
import LifecycleStagePill from "../../../components/LifecycleStagePill";
import { useParams } from "react-router-dom";
import { useHover } from "../../../hooks";

import { COLOR_RANGE, RITEKIT_URL } from "../../../constants";

import {
  Sparkline,
  LineSeries,
  BarSeries,
  HorizontalReferenceLine,
  BandLine,
  PatternLines,
  PointSeries,
  WithTooltip
} from "@data-ui/sparkline";
import { allColors } from "@data-ui/theme";

const submitIntegrationCredentials = (connector, key, setLoaded) => {
  axios
    .post(API_HOST + `/connector_info`, {
      service: connector,
      key: key
    })
    .then(responseJson => {
      // setLoaded(true)
      window.close();
    });
};

function CustomConnector({}) {
  let { connector } = useParams();
  const [text, setText] = useState("");
  const [loaded, setLoaded] = useState(false);

  let w = Math.min(0.75 * window.screen.width, 1000);
  let h = Math.min(0.8 * window.screen.height, 800);
  window.resizeTo(w, h);
  window.moveTo((window.screen.width - w) / 2, (window.screen.height - h) / 2);

  return (
    <>
      <Main style={{ background: "white", padding: "0px", minWidth: "1000px" }}>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #f7f8fa",
            alignItems: "center",
            justifyContent: "space-around"
          }}
        >
          <div style={{ display: "flex", minWidth: "56.25rem" }}>
            <div>
              <Logo
                className={connector}
                src={"/integration_logos/" + connector + "-symbol.svg"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "10px"
              }}
            >
              <div style={{ fontSize: "1.5rem" }}>
                {connector.charAt(0).toUpperCase() + connector.substring(1)}
              </div>
              <div
                style={{
                  fontSize: ".8125rem",
                  color: "#4e545b",
                  paddingTop: "3px"
                }}
              >
                New Connector
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "4.0625rem .625rem"
          }}
        >
          <div style={{ minWidth: "56.25rem", display: "flex" }}>
            <div
              style={{
                width: "100px",
                fontSize: ".8125rem",
                paddingTop: "8px",
                textAlign: "center"
              }}
            >
              API Key
            </div>
            {/* {loaded ? <p>Hello</p> : ''} */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextInput onChange={e => setText(e.target.value)} value={text} />
              <div>
                <SubmitButton
                  appearance="primary"
                  marginTop={40}
                  onClick={() =>
                    submitIntegrationCredentials(connector, text, setLoaded)
                  }
                >
                  <span>SAVE</span>
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

const SubmitButton = styled(Button)`
  font-weight: bold;
`;

const Logo = styled.img`
  min-width: 95px;
  min-height: 95px;
  width: 95px;
  height: 95px;

  &.pipedrive {
  }
`;

export default CustomConnector;
