import React from "react";
import * as dateFns from "date-fns";
import styled from "styled-components";
import { Pane, Icon } from "evergreen-ui";

const RefreshButton = ({
  lastUpdateTime,
  refreshTrigger,
  setRefreshTrigger
}) => {
  const stale =
    dateFns.differenceInHours(
      Date.now(),
      dateFns.fromUnixTime(lastUpdateTime / 1000)
    ) > 0;

  const recentFetch =
    dateFns.differenceInMinutes(
      Date.now(),
      dateFns.fromUnixTime(lastUpdateTime / 1000)
    ) < 2;

  const hideRefreshButton = !!refreshTrigger || stale;

  return (
    <StyledRefreshButton
      fontSize={12}
      title="Time since data refreshed"
      className={hideRefreshButton ? "" : "show-refresh-button"}
      onClick={() => setRefreshTrigger(refreshTrigger + 1)}
    >
      <Pane
        className="on-hover"
        color="#6B7280"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon icon="repeat" size={11} marginRight={8} title="Refresh data" />
        <Pane is="span">Refresh data</Pane>
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        className="time"
        width="100%"
        justifyContent="center"
      >
        <Icon
          icon="time"
          size={11}
          marginRight={8}
          color="#9FA6B2"
          title="Time since last refresh"
        />
        <Pane is="span" color="#9FA6B2">
          {dateFns.formatDistanceToNow(
            dateFns.fromUnixTime(lastUpdateTime / 1000)
          )}{" "}
          ago{hideRefreshButton && !recentFetch && ", refreshing…"}
        </Pane>
      </Pane>
    </StyledRefreshButton>
  );
};

const StyledRefreshButton = styled(Pane)`
  display: flex;
  margin: 0 12px;
  padding: 0 12px;
  border-radius: 4px;
  position: relative;
  cursor: default;
  min-width: 120px;

  .on-hover {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 12px;
    right: 12px;
    bottom: 0;
  }

  &.show-refresh-button {
    cursor: pointer;

    &:hover {
      background-color: #f9fafb;

      .time {
        opacity: 0;
      }
      .on-hover {
        opacity: 1;
      }
    }
  }
`;

export default RefreshButton;
