import React, { useState, useEffect } from "react";
import { API_HOST } from "../../constants";
import axios from "axios";
import styled from "styled-components";
import he from "he";
import {
  Pane,
  Table,
  Icon,
  SelectMenu,
  IconButton,
  Autocomplete,
  TextInput,
  Button
} from "evergreen-ui";
import Helmet from "react-helmet";

import * as dateFns from "date-fns";
import extractDomain from "extract-domain";

import {
  Sparkline,
  LineSeries,
  HorizontalReferenceLine,
  BandLine,
  PatternLines,
  PointSeries,
  WithTooltip
} from "@data-ui/sparkline";
import { allColors } from "@data-ui/theme";

import {
  getLocalData,
  setLocalData,
  fetchData,
  useFetchAPI
} from "../../helpers/data";

import { Row, Card, Header, Center, Main } from "../../components/base";
import { useHover } from "../../hooks";
import { COLOR_RANGE, RITEKIT_URL } from "../../constants";
import { Link } from "react-router-dom";

const integrations = [
  { id: "amplitude", label: "Amplitude", type: "analytics", enabled: true },
  { id: "pipedrive", label: "Pipedrive", type: "CRM", enabled: true },
  { id: "front", label: "Front", type: "CRM", enabled: true },
  { id: "mixpanel", label: "Mixpanel", type: "analytics", enabled: false },
  { id: "heap", label: "Heap", type: "analytics", enabled: false },
  { id: "pendo", label: "Pendo", type: "analytics", enabled: false },
  { id: "optimizely", label: "Optimizely", type: "analytics", enabled: false },
  { id: "segment", label: "Segment", type: "analytics", enabled: false },
  {
    id: "google-analytics-360",
    label: "Google Analytics",
    type: "analytics",
    enabled: false
  },
  { id: "salesforce", label: "Salesforce", type: "CRM", enabled: false },
  { id: "hubspot", label: "HubSpot", type: "CRM", enabled: false },
  { id: "stripe", label: "Stripe", type: "Payments", enabled: false },
  { id: "intercom", label: "Intercom", type: "Communication", enabled: false },
  { id: "kustomer", label: "Kustomer", type: "Communication", enabled: false },
  {
    id: "mailchimp",
    label: "Mailchimp",
    type: "Communication",
    enabled: false
  },
  {
    id: "help-scout",
    label: "Help Scout",
    type: "Communication",
    enabled: false
  }
];

const initializeIntegration = serviceId => {
  var w = 600;
  var h = 400;
  var left = window.innerWidth / 2 - w / 2;
  var top = window.innerHeight / 2 - h / 2;

  if (serviceId == "pipedrive") {
    window.open(
      `${
        window.location.hostname == "localhost"
          ? "http://localhost:3000"
          : "https://" + window.location.hostname
      }/connect/pipedrive`,
      "Authorization",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, status=false, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  } else {
    let token = "";
    let redirect_uri = "https://app.quantif.ai/connect";
    axios
      .get(API_HOST + `/connect_token?service=${serviceId}`)
      .then(responseJson => {
        console.log(responseJson);
        token = responseJson.data.token;
        window.open(
          `https://fivetran.com/connect-card/setup?redirect_uri=${redirect_uri}&auth=${token}`,
          "Authorization",
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, status=false, copyhistory=no, width=" +
            w +
            ", height=" +
            h +
            ", top=" +
            top +
            ", left=" +
            left
        );
      });
  }
};

function Connect({
  selectedCsmEmail,
  accounts,
  owners,
  userDomain,
  Nav,
  user
}) {
  const [setConnectorFilter] = useState("");
  console.log(setConnectorFilter);

  return (
    <>
      <Helmet defer={false} title={`Connect | Quantifai`}>
        <link rel="shortcut icon" href="/favicon-96x96.png" />
      </Helmet>
      <Row
        paddingX={40}
        paddingY={19}
        minHeight={70}
        justifyContent="space-between"
        alignItems="center"
        flexShrink={0}
      >
        <Pane
          is="img"
          src={`https://logo.clearbit.com/eventgeek.com`}
          width={32}
          height={32}
          marginRight={10}
        />
        <Arrow>↔</Arrow>
        <Pane
          is="img"
          src={`https://logo.clearbit.com/quantif.ai`}
          width={32}
          height={32}
          marginRight={6}
        />
        <Pane
          marginLeft={6}
          marginRight="auto"
          display="flex"
          alignItems="center"
          cursor="default"
        >
          Data Connections
        </Pane>
        <Pane marginRight={12}>
          <SearchBar integrations={integrations} />
        </Pane>
      </Row>
      <Main>
        <Integrations>
          {integrations.map(i => {
            return (
              <Integration
                className={i.enabled ? "enabled" : "disabled"}
                key={i.id}
                onClick={() => (i.enabled ? initializeIntegration(i.id) : "")}
              >
                <Logo
                  className={i.id}
                  src={"/integration_logos/" + i.id + "-symbol.svg"}
                />
                <Label>{i.label}</Label>
              </Integration>
            );
          })}
        </Integrations>
      </Main>
    </>
  );
}

const SearchBar = integrations => {
  const [setConnectorFilter] = useState("");
  const sortedItems = integrations.integrations;

  return (
    <Autocomplete
      title="Search connectors..."
      items={sortedItems}
      itemToString={item => (!item ? "" : item.label)}
    >
      {props => {
        const { getInputProps, getRef, inputValue } = props;
        return (
          <TextInput
            height={40}
            placeholder="Search connectors..."
            value={inputValue || ""}
            innerRef={getRef}
            onChangeText={changedItem => setConnectorFilter(changedItem)}
            {...getInputProps()}
          />
        );
      }}
    </Autocomplete>
  );
};

const Integrations = styled.div`
  display: flex;
  padding-top: 50px;
  flex-wrap: wrap;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Integration = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 95px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  background-color: white;

  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-property: all;

  cursor: pointer;

  &.enabled {
    &:hover {
      box-shadow: 0 24px 48px rgba(0, 0, 0, 0.2);
    }
  }

  &.disabled {
    opacity: 0.5;
  }
`;

const Logo = styled.img`
  min-width: 95px;
  min-height: 95px;
  width: 95px;
  height: 95px;

  &.pipedrive {
  }
`;

const Label = styled.span`
  margin-top: 10px;
  text-align: center;
  font-size: 0.75rem;
  color: #909dab;
`;

const Arrow = styled.span`
  color: #4f5154;
`;

export default Connect;
