import { Pane } from "evergreen-ui";
import styled from "styled-components";

const Card = styled(Pane)`
  border-radius: 6px;
  /*box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background-color: white;
`;

export default Card;
