import React from "react";
import styled from "styled-components";
import { Pane } from "evergreen-ui";
import * as dateFns from "date-fns";

import { Row } from "../../components/base";
import capitalise from "../../helpers/capitalise";
import { CustomerDomainByCustomerId, CustomerId } from "../../constants";
import { EVENT_TYPES, ANY_PAGE } from "./constants";

const getCustomUserName = (user, userDomain) => {
  if (userDomain === CustomerDomainByCustomerId[CustomerId.Upflow]) {
    return capitalise(
      user.name.toLowerCase().replace(/["]/g, "").replace(` render`, ""),
      true
    );
  }
  return user.email;
};

const customAccountFilter = (userDomain, account) => {
  if (userDomain === CustomerDomainByCustomerId[CustomerId.Upflow]) {
    return !account.toLowerCase().includes("maltem");
  }
  return true;
};

const ExampleAlert = ({
  trigger,
  triggerConditionType,
  triggerConditionEvent,
  triggerConditionPage,
  triggerConditionWaitDuration,
  triggerConditionWaitDurationUnit,
  frequency,
  frequencyTime,
  frequencyTimeAMPM,
  userDomain,
  recentAccounts
}) => {
  let verb = triggerConditionType === EVENT_TYPES.EVENT ? "did" : "viewed";
  let result = "";
  if (triggerConditionType === EVENT_TYPES.EVENT) {
    verb = "did";
    result = `"${triggerConditionEvent || "Clicked Button"}"`;
  } else {
    verb = "viewed";
    result =
      triggerConditionPage !== ANY_PAGE ? triggerConditionPage : "Dashboard";
  }

  let collectiveVerb = "";

  if (trigger === "any") {
    collectiveVerb = "active";
  } else if (trigger === "specific") {
    collectiveVerb = `who ${verb} ${result}`;

    if (
      triggerConditionType === EVENT_TYPES.PAGE &&
      triggerConditionPage === ANY_PAGE
    ) {
      collectiveVerb = "who viewed any page";
    }
  } else if (trigger === "lack") {
    if (triggerConditionType === EVENT_TYPES.PAGE) {
      collectiveVerb = `who didn't view ${result}`;

      if (triggerConditionPage === ANY_PAGE) {
        collectiveVerb = "who didn't view any page";
      }
    } else if (triggerConditionType === EVENT_TYPES.EVENT) {
      collectiveVerb = `who didn't do ${result}`;
    }
    collectiveVerb = `${collectiveVerb} for the past ${triggerConditionWaitDuration} ${triggerConditionWaitDurationUnit}`;
  }

  let dateRange = "";
  if (trigger !== "lack") {
    if (frequency === "daily") {
      dateRange = `on ${dateFns.format(Date.now(), "MMM d")}`;
    } else if (frequency === "weekly") {
      dateRange = "in previous 7 days";
    }
  }

  const sampleUser = (Object.values(recentAccounts)[0] || [])[0];

  return (
    <Pane
      zIndex={30}
      position="fixed"
      top="22%"
      cursor="default"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      left={0}
      right={620}
    >
      <Pane
        is="h2"
        color="#c9d0d6"
        fontWeight={600}
        fontSize={19}
        textShadow="0px 0px 6px #788898, 0px 0px 2px #788898"
      >
        Example
      </Pane>
      <Pane
        minWidth={500}
        padding={20}
        backgroundColor="white"
        borderRadius={8}
      >
        {frequency === "immediately" && (
          <Row>
            <SlackLogo>
              <Pane>Q</Pane>
              {/* <Pane is="img" src="https://avatars.slack-edge.com/2020-01-12/905257470311_0a6607f46facb16670d6_88.png"/> */}
            </SlackLogo>
            <SlackMessageContent>
              <Row marginBottom={5} alignItems="baseline">
                <SlackMessageContentAuthor>
                  Customer Usage Bot
                </SlackMessageContentAuthor>
                <SlackMessageContentTime>2:44 PM</SlackMessageContentTime>
              </Row>
              <Pane>
                <Pane is="span">[{sampleUser.group_name}]</Pane>{" "}
                <Pane is="span">
                  {getCustomUserName(sampleUser, userDomain)}
                </Pane>{" "}
                <Pane is="span">{verb}</Pane>{" "}
                <Pane is="span" color="rgb(18, 100, 163)">
                  {result}
                </Pane>
              </Pane>
            </SlackMessageContent>
          </Row>
        )}
        {frequency !== "immediately" && (
          <Row>
            <SlackLogo>
              <Pane>Q</Pane>
              {/* <Pane is="img" src="https://avatars.slack-edge.com/2020-01-12/905257470311_0a6607f46facb16670d6_88.png"/> */}
            </SlackLogo>
            <SlackMessageContent>
              <Row marginBottom={5} alignItems="baseline">
                <SlackMessageContentAuthor>
                  Customer Usage Bot
                </SlackMessageContentAuthor>
                <SlackMessageContentTime>
                  {frequencyTime}:00 {frequencyTimeAMPM}
                </SlackMessageContentTime>
              </Row>
              <Pane fontStyle="italic" marginBottom={8}>
                Users {collectiveVerb} {dateRange}:
              </Pane>

              {Object.keys(recentAccounts)
                .filter(account => customAccountFilter(userDomain, account))
                .slice(0, 4)
                .map(account => {
                  const usersPerAccount = recentAccounts[account];

                  return (
                    <SlackMessageParagraph>
                      <Pane fontWeight={700}>
                        {account} ({usersPerAccount.length})
                      </Pane>
                      {usersPerAccount.map(user => (
                        <Pane>
                          <Span>{getCustomUserName(user, userDomain)}</Span>
                        </Pane>
                      ))}
                    </SlackMessageParagraph>
                  );
                })}
            </SlackMessageContent>
          </Row>
        )}
      </Pane>
    </Pane>
  );
};

const SlackLogo = styled(Pane)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2px hsla(210, 30%, 87%, 1);
  border-radius: 23px;
  width: 36px;
  height: 36px;
  margin-top: 2px;
  margin-right: 9px;

  & > div {
    font-weight: 700;
    font-size: 22px;
    opacity: 0.9;
  }
`;

const SlackMessageParagraph = styled(Pane)`
  color: rgb(29, 28, 29);
  font-size: 14px;
  line-height: 1.46668;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const SlackMessageContent = styled(Pane)`
  font-size: 14px;
  color: rgb(29, 28, 29);
  font-family: Lato;
`;

const Span = props => <Pane is="span" {...props} />;
const SlackMessageContentAuthor = styled(Span)`
  font-weight: 900;
`;

const SlackMessageContentTime = styled(Span)`
  color: rgb(97, 96, 97);
  font-size: 12px;
  margin-left: 8px;
`;

export default ExampleAlert;
