import React from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";

const getTo = to => {
  // If there is an `as` query param, we want to preserve it across navigation.
  const { as } = queryString.parse(window.location.search);

  if (!to || !as) {
    return to;
  }

  // Link accepts `to` as string or object. We'll convert everything to an object for convenience.
  let parsedTo;
  if (typeof to === "string") {
    // e.g. to="/accounts?hidden=true"
    parsedTo = queryString.parseUrl(to);
  } else {
    // e.g. to={ pathname: "/accounts", search: "?hidden=true" }
    parsedTo = {
      ...to,
      url: to.pathname,
      query: queryString.parse(to.search || {})
    };
  }

  const queryWithAsParam = { ...parsedTo.query, as };

  return {
    ...parsedTo,
    pathname: parsedTo.url,
    search: "?" + queryString.stringify(queryWithAsParam)
  };
};

export default props => <Link {...props} to={getTo(props.to)} />;
