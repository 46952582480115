import React from "react";

import { Icon } from "evergreen-ui";
import { allColors } from "@data-ui/theme";

const TrendDirectionIcon = ({ changeRatio }) => {
  if (changeRatio > 1) {
    return <Icon icon="caret-up" color={allColors.green[7]} />;
  } else {
    return <Icon icon="caret-down" color={allColors.red[7]} />;
  }
};

export default TrendDirectionIcon;
