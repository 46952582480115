export function clearbitRevenueSortValue(clearbitRevenue = "") {
  switch (clearbitRevenue) {
    case "$0-$1M":
      return 1;
    case "$1M-$10M":
      return 2;
    case "$10M-$50M":
      return 3;
    case "$50M-$100M":
      return 4;
    case "$100M-$250M":
      return 5;
    case "$250M-$500M":
      return 6;
    case "$500M-$1B":
      return 7;
    case "$1B-$10B":
      return 8;
    case "$10B+":
      return 9;
    default:
      return 0;
  }
}
