export function companySizeSortValue(companySizeSortValue = "") {
  switch (companySizeSortValue) {
    case "Small":
      return 1;
    case "Medium":
      return 2;
    case "Large":
      return 3;
    default:
      return 0;
  }
}
