import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  Icon,
  Select,
  SelectMenu,
  Autocomplete,
  TextInput,
  Pane,
  Button,
  Spinner
} from "evergreen-ui";
import queryString from "query-string";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory
} from "react-router-dom";

import Link from "./components/Link";
import RefreshButton from "./components/RefreshButton";
import { Row, Column, Center, Main } from "./components/base";
import Accounts from "./pages/Accounts";
import AccountDetail from "./pages/Accounts/AccountDetail";
import Alerts from "./pages/Alerts";
import ConnectROSS from "./pages/ConnectRoss";
import Connect from "./pages/Connect";
import CustomConnector from "./pages/Connect/Custom";
import {
  API_HOST,
  customerSettings,
  CustomerDomainByCustomerId,
  FREEMAIL_DOMAINS,
  CustomerId
} from "./constants";
import { useFetchAPI } from "./helpers/data";
import { useAuth0 } from "./react-auth0-spa";

import PrivateRoute from "./PrivateRoute";

const UserSelector = styled(Row)`
  align-items: center;
`;

const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 60px;
  margin-right: 10px;
`;
const UserName = styled.div`
  font-weight: 600;
  color: #475872;
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  left: 16px;
`;

const StyledLogoLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  min-width: 38px;
  min-height: 40px;
  color: #161e2e;
`;

class ErrorBoundary extends React.Component {
  state = { refreshKey: 1 };

  componentDidCatch() {
    this.setState(({ refreshKey }) => ({ refreshKey: refreshKey + 1 }));
  }

  render() {
    return this.props.children({ refreshKey: this.state.refreshKey });
  }
}

const SearchBar = ({ accounts, selectedCsmEmail, userDomain }) => {
  const history = useHistory();
  const allUsers = accounts
    .map(account =>
      !account.users
        ? []
        : Object.keys(
            account.users.reduce((sum, curr) => ({ ...sum, [curr]: true }), {})
          ).map(u => ({
            type: "user",
            email: u,
            accountName: account.name,
            group_id: account.group_id,
            accountType: account.traits ? account.traits.TYPE : null
          }))
    )
    .flat();

  const sortedItems = [...accounts, ...allUsers].sort((a, b) => {
    if (!a.assigned_csms && !b.assigned_csms) {
      return 0;
    }
    if (a.type === "user" && b.type !== "user") {
      return 1;
    }
    if (a.type !== "user" && b.type === "user") {
      return -1;
    }
    const doesAcontainCSM = a.assigned_csms.includes(selectedCsmEmail);
    const doesBcontainCSM = b.assigned_csms.includes(selectedCsmEmail);
    if (doesAcontainCSM && !doesBcontainCSM) {
      return -1;
    }
    if (!doesAcontainCSM && doesBcontainCSM) {
      return 1;
    }
    if (doesAcontainCSM && doesBcontainCSM) {
      if (a.current_mrr > b.current_mrr) {
        return -1;
      }
      if (a.current_mrr < b.current_mrr) {
        return 1;
      }
    }
    return 0;
  });

  return (
    <ErrorBoundary>
      {({ refreshKey }) => (
        <Autocomplete
          key={refreshKey}
          title="Search accounts and users..."
          onChange={changedItem =>
            history.push("/accounts/" + changedItem.group_id)
          }
          items={sortedItems}
          itemToString={item => {
            if (!item) {
              return "";
            }
            const identifier =
              userDomain === "eventgeek.com"
                ? item.accountType || (item.traits && item.traits.TYPE)
                : item.group_id.slice(0, 4);
            if (item.type === "user") {
              return `${item.email} - ${item.accountName} (${identifier})`;
            }
            return `${item.name} (${identifier})`;
          }}
        >
          {props => {
            const { getInputProps, getRef, inputValue } = props;
            return (
              <TextInput
                height={40}
                placeholder="Search accounts and users..."
                value={inputValue || ""}
                innerRef={getRef}
                {...getInputProps()}
              />
            );
          }}
        </Autocomplete>
      )}
    </ErrorBoundary>
  );
};

const STANDARD_LOGO_WIDTH = 32;

const Logo = ({ userDomain, ...props }) => (
  <Pane
    display="flex"
    alignItems="center"
    justifyContent="center"
    marginRight={
      customerSettings.LOGO_MARGIN[userDomain] ||
      6 +
        ((customerSettings.LOGO_WIDTH[userDomain] || STANDARD_LOGO_WIDTH) -
          STANDARD_LOGO_WIDTH) /
          2
    }
    maxWidth={STANDARD_LOGO_WIDTH}
    maxHeight={STANDARD_LOGO_WIDTH}
    {...props}
  >
    <Pane
      is="img"
      src={`https://logo.clearbit.com/${userDomain}`}
      maxWidth={customerSettings.LOGO_WIDTH[userDomain] || STANDARD_LOGO_WIDTH}
      maxHeight={customerSettings.LOGO_WIDTH[userDomain] || STANDARD_LOGO_WIDTH}
    />
  </Pane>
);

const Navbar = ({
  userDomain,
  isLoading,
  validOwners,
  accounts,
  selectedCsmEmail,
  setSelectedCsmEmail,
  pageTitle,
  lastUpdateTime,
  refreshTrigger,
  setRefreshTrigger,
  activeQntAccounts,
  user,
  getTokenSilently,
  children
}) => (
  <Row
    paddingX={40}
    paddingY={19}
    minHeight={70}
    justifyContent="space-between"
    flexShrink={0}
  >
    <StyledLogoLink to="/">
      {!isLoading && (
        <>
          <Pane
            onClick={e => {
              const isDetailPage = window.location.pathname.includes(
                "/accounts"
              );

              if (!isDetailPage && !!activeQntAccounts.length) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            {!activeQntAccounts.length && <Logo userDomain={userDomain} />}
            {!!activeQntAccounts.length && (
              <SelectMenu
                marginX={6}
                hasTitle={false}
                height={33 * activeQntAccounts.length}
                hasFilter={false}
                options={activeQntAccounts.map(q => ({
                  label: q.id,
                  value: q.domains,
                  icon: `https://logo.clearbit.com/${q.domains}`
                }))}
                onSelect={async ({ value }) => {
                  const selectedDomain = value;
                  const selectedId = activeQntAccounts.find(
                    q => q.domains === selectedDomain
                  ).id;

                  const token = await getTokenSilently();
                  const userResult = await axios.put(
                    `${API_HOST}/quantifai/user`,
                    { qnt_customer_id: selectedId },
                    {
                      headers: { Authorization: `Bearer ${token}` }
                    }
                  );
                  window.location.reload();
                }}
                selected={userDomain}
              >
                <Logo userDomain={userDomain} />
              </SelectMenu>
            )}
          </Pane>
        </>
      )}
    </StyledLogoLink>

    <Pane
      marginLeft={6}
      marginRight="auto"
      display="flex"
      alignItems="center"
      cursor="default"
    >
      {pageTitle}
    </Pane>
    {children}
    {lastUpdateTime !== undefined && (
      <RefreshButton
        lastUpdateTime={lastUpdateTime}
        setRefreshTrigger={setRefreshTrigger}
        refreshTrigger={refreshTrigger}
      />
    )}

    {!isLoading && (
      <Pane marginRight={12}>
        <SearchBar
          accounts={accounts}
          selectedCsmEmail={selectedCsmEmail}
          userDomain={userDomain}
        />
      </Pane>
    )}
    {userDomain !== "h1insights.com" && (
      <UserSelector>
        {!isLoading && (
          <Select
            height={40}
            onChange={event => setSelectedCsmEmail(event.target.value)}
            value={selectedCsmEmail}
          >
            {validOwners.map((csm, i) => (
              <option key={csm.traits.EMAIL} value={csm.traits.EMAIL}>
                {csm.traits.NAME}
              </option>
            ))}
            <option value="">All</option>
          </Select>
        )}
      </UserSelector>
    )}
  </Row>
);

function App() {
  const [selectedCsmEmail, setSelectedCsmEmail] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [clearedOrgParam, setClearedOrgParam] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const a = useAuth0();

  const {
    isAuthenticated,
    loginWithRedirect,
    user,
    loading,
    getTokenSilently
  } = a;

  window.auth0 = a;

  const isDetailPage = window.location.pathname.includes("/accounts");

  const accountsMeta = useFetchAPI(
    "accounts",
    `/accounts`,
    [],
    {
      method: "POST",
      postBody: {},
      identifier: "all_" + customerId,
      getDate: true,
      autoRefresh: !isDetailPage
    },
    !loading && !!isAuthenticated && !!customerId,
    refreshTrigger
  );
  const accounts = accountsMeta.value || [];

  const owners = useFetchAPI(
    "owners",
    `/owners`,
    [],
    {
      method: "POST",
      body: {},
      identifier: "all_" + customerId
    },
    !loading && !!isAuthenticated && !!customerId
  );

  const userResult = useFetchAPI(
    "auth",
    "/user",
    false,
    {
      method: "PUT",
      body: { user_id: user ? user.sub : null },
      fetchOnly: user ? user.email.includes("@quantif.ai") : false
    },
    !loading && isAuthenticated && user && clearedOrgParam
  );

  const qntAccounts = useFetchAPI(
    "qnt_accounts",
    `/quantifai/accounts`,
    [],
    {
      fetchOnly: true
    },
    !loading && user && user.email.includes("@quantif.ai")
  );

  const onUserAuthenticated = async user => {
    const { org } = queryString.parse(window.location.search);
    if (user.email.includes("@quantif.ai") && org) {
      const token = await getTokenSilently();

      const userResult = await axios.put(
        `${API_HOST}/quantifai/user`,
        { qnt_customer_id: org },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
    }

    setClearedOrgParam(true);
  };

  useEffect(() => {
    if (user && isAuthenticated) {
      onUserAuthenticated(user);
    }
  }, [isAuthenticated, user]);

  console.log(userResult);

  console.log("user", user);
  console.log("owners", owners);

  const identify = async () => {
    window.localStorage.setItem("_q_existing", true);
    if (window.analytics) {
      if (!user.email.includes("@quantif.ai")) {
        window.analytics.identify(user.sub, {
          email: user.email
        });
      } else {
        window.analytics = null;
      }
    }
  };
  useEffect(() => {
    const { as } = queryString.parse(window.location.search);
    if (!customerId && (userResult || as)) {
      setCustomerId(as || userResult.account_id);
    }
  }, [userResult]);

  useEffect(() => {
    if (!loading && isAuthenticated && user) {
      identify();
    } else if (!loading && !isAuthenticated) {
      let options = {};

      if (window.location.pathname.indexOf("/connect") !== 0) {
        if (
          window.localStorage.getItem("_q_existing") !== "true" &&
          !window.location.search.includes("org=")
        ) {
          options = {
            access_type: "offline",
            connection_scope: [
              "https://www.googleapis.com/auth/gmail.readonly",
              "https://www.googleapis.com/auth/calendar.events.readonly",
              "https://www.googleapis.com/auth/calendar.readonly"
            ],
            approval_prompt: "force"
          };
        }

        loginWithRedirect(options);
      }
    }
  }, [user, loading, isAuthenticated]);

  useEffect(() => {
    if (customerId === "appcues") {
      setSelectedCsmEmail("emma@appcues.com");
    } else if (customerId === "shipbob") {
      setSelectedCsmEmail("jrogers@shipbob.com");
    }
  }, [customerId]);

  if (loading) {
    return <></>;
  }

  const userDomain = CustomerDomainByCustomerId[customerId];

  let validOwners = typeof owners === "string" ? JSON.parse(owners) : owners;
  if (customerId === "h1") {
    validOwners = owners.filter(csm =>
      [
        "ariel.katz@h1insights.com",
        "katie.chase@h1insights.com",
        "emma.schroeter@h1insights.com"
      ].includes(csm.traits.EMAIL)
    );
  } else if (customerId === "shipbob") {
    validOwners = owners.filter(
      csm =>
        csm.traits.TITLE === "MS" &&
        !["zgasa", "rbero", "blath", "jmalm"].includes(csm.traits.ALIAS)
    );
  } else if (customerId === "quantifai") {
    validOwners = [
      {
        owner_id: "ALL",
        traits: {
          EMAIL: "-",
          NAME: "-"
        }
      }
    ];
  }

  let filteredAccounts = accounts;

  if (customerId === "shipbob") {
    const validOwnerIds = validOwners.map(o => o.owner_id);
    filteredAccounts = filteredAccounts.filter(
      a =>
        a.users && validOwnerIds.includes(a.traits.MERCHANT_SUCCESS_MANAGER_C)
    );
  }
  if (customerId === "ross") {
    // For ROSS we want to filter out all universities, all freemail domains, and
    // companies without any usage.
    filteredAccounts = accounts.filter(
      a =>
        !FREEMAIL_DOMAINS.has(a.group_id) &&
        !!a.traits.DAILY_EVENT_COUNTS &&
        (!a.traits.COMPANY_TAGS || !a.traits.COMPANY_TAGS.includes("Education"))
    );
  }

  if (customerId === CustomerId.Instabug) {
    filteredAccounts = accounts.map(account => ({
      ...account,
      stats: account.stats || {}
    }));
  }

  const Nav = props => (
    <Navbar
      accounts={accounts}
      accountsLastUpdateTime={accounts ? accounts.date : 0}
      selectedCsmEmail={selectedCsmEmail}
      setSelectedCsmEmail={setSelectedCsmEmail}
      validOwners={validOwners}
      userDomain={userDomain}
      refreshTrigger={refreshTrigger}
      setRefreshTrigger={setRefreshTrigger}
      isLoading={!(!loading && !!isAuthenticated && !!customerId && !!user)}
      activeQntAccounts={
        qntAccounts ? qntAccounts.filter(q => !q.inactive) : []
      }
      user={user}
      getTokenSilently={getTokenSilently}
      isDetailPage={isDetailPage}
      {...props}
    />
  );

  return (
    <Router>
      <Column height="100%">
        <Switch>
          <Route
            path="/connect/:connector"
            component={CustomConnector}
            userDomain={userDomain}
            Nav={Nav}
          />
          <Route
            path="/connect"
            component={Connect}
            userDomain={userDomain}
            Nav={Nav}
          />
        </Switch>
        <Switch>
          <Route
            path="/connect-ROSS"
            component={ConnectROSS}
            userDomain={userDomain}
            Nav={Nav}
          />
        </Switch>

        {!loading &&
          !isAuthenticated &&
          window.location.pathname.indexOf("/connect") !== 0 && (
            <>
              <Nav />
              <Main>
                <Pane
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    height={40}
                    onClick={() =>
                      loginWithRedirect({
                        access_type: "offline",
                        connection_scope:
                          "https://www.googleapis.com/auth/gmail.readonly",
                        approval_prompt: "force"
                      })
                    }
                  >
                    Login
                  </Button>
                </Pane>
              </Main>
            </>
          )}
        {!!customerId && (
          <Switch>
            <PrivateRoute
              path="/accounts/:accountId"
              owners={userDomain === "quantif.ai" ? validOwners : owners}
              component={AccountDetail}
              userDomain={userDomain}
              Nav={Nav}
            />
            <PrivateRoute
              path="/alerts"
              component={Alerts}
              owners={userDomain === "quantif.ai" ? validOwners : owners}
              userDomain={userDomain}
              Nav={Nav}
            />
            <Redirect from="/accounts" to="/" />
            <PrivateRoute
              path="/"
              component={Accounts}
              accountsLastUpdateTime={accountsMeta ? accountsMeta.date : 0}
              selectedCsmEmail={selectedCsmEmail}
              accounts={filteredAccounts}
              allAccountsLength={accounts.length}
              owners={userDomain === "quantif.ai" ? validOwners : owners}
              userDomain={userDomain}
              Nav={Nav}
            />
          </Switch>
        )}
        {isAuthenticated && !customerId && (
          <>
            <Nav />
            <Main>
              <Center height="93%" justifyContent="center">
                <Spinner />
              </Center>
            </Main>
          </>
        )}
      </Column>
    </Router>
  );
}

export default App;
