// `recurse` will capitalise the start of every phrase split by the `delimiter`.

export default (str, recurse = false, delimiter = " ") => {
  try {
    return !str
      ? str
      : !recurse
      ? str[0].toUpperCase() + str.slice(1)
      : str
          .split(delimiter)
          .map(s => s[0].toUpperCase() + s.slice(1).toLowerCase())
          .join(delimiter);
  } catch (e) {
    return str;
  }
};
