import React from "react";
import TrendDirectionIcon from "../../TrendDirectionIcon";
import { toPercent } from "../../../helpers/ratio";
import { toPercentWithCommas } from "../../../helpers/percent";

const MetricChangeOverTime = ({ changeRatio, numOfTotalEvents }) => {
  const value = metricValue(changeRatio);
  return (
    <>
      {value === "N/A" ? "" : <TrendDirectionIcon changeRatio={changeRatio} />}
      {value} ({numOfTotalEvents ? numOfTotalEvents.toLocaleString() : 0})
    </>
  );
};

function metricValue(changeRatio) {
  if (changeRatio === null || changeRatio === undefined) {
    return "N/A";
  }
  const percent = toPercent(changeRatio);

  return `${percent > 0 ? "+" : ""}${toPercentWithCommas(percent)}%`;
}

export default MetricChangeOverTime;
