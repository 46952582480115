export const EVENT_TYPES = {
  EVENT: "event",
  PAGE: "page"
};

export const ANY_PAGE = "Any Page";

export const ALERT_STATUS = {
  PENDING: "pending",
  ACTIVE: "active",
  INACTIVE: "inactive"
};

export const STATUS_COLORS = {
  [ALERT_STATUS.PENDING]: {
    backgroundColor: "#FFE680",
    color: "#FFAA39"
  },
  [ALERT_STATUS.ACTIVE]: {
    backgroundColor: "#c5e8da",
    color: "#4099A2"
  },
  [ALERT_STATUS.INACTIVE]: {
    backgroundColor: "#E7ECF3",
    color: "#7E89A9"
  }
};

