import React from "react";
import { Pane } from "evergreen-ui";
import { LIFECYCLE_STAGES } from "../../constants";

const LifecycleStagePill = ({ stage }) => {
  return (
    <Pane
      display="inline-flex"
      paddingTop={3}
      paddingBottom={4}
      paddingX={12}
      borderRadius={60}
      backgroundColor={LIFECYCLE_STAGES[stage || "Pending"].backgroundColor}
      color={LIFECYCLE_STAGES[stage || "Pending"].color}
      fontWeight={600}
      fontSize="14px"
      lineHeight="19px"
    >
      {LIFECYCLE_STAGES[stage || "Pending"].label || stage}
    </Pane>
  );
};

export default LifecycleStagePill;
