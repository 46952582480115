import styled from "styled-components";

const Main = styled.div`
  background-color: #f4f5f7;
  border-top: 1px solid #e4e8eb;
  flex-grow: 1;
  padding: 20px;
`;

export default Main;
