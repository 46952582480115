import Card from "../Card";
import styled from "styled-components";

const Panel = styled(Card)`
  display: flex;
  flex-direction: column;

  padding: ${props => props.paddingY || 23}px ${props => props.paddingX || 16}px;
  padding-bottom: ${props => props.paddingBottom || props.paddingY || 23}px;
  flex-grow: 1;

  & + &,
  & + .panel,
  .panel + {
    margin-left: 42px;
  }
`;

export default Panel;
