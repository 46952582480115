import React, { useState, useEffect } from "react";
import styled from "styled-components";
import he from "he";
import {
  Pane,
  Table,
  Icon,
  SelectMenu,
  IconButton,
  Button
} from "evergreen-ui";
import Helmet from "react-helmet";

import * as dateFns from "date-fns";
import extractDomain from "extract-domain";

import { Sparkline, LineSeries } from "@data-ui/sparkline";
import { allColors } from "@data-ui/theme";

import { getLocalData, setLocalData } from "../../helpers/data";
import { companySizeSortValue } from "../../helpers/company/properties";

import { Row, Column, Card, Header, Main } from "../../components/base";
import { useHover } from "../../hooks";
import { COLOR_RANGE, domainToCustomerId } from "../../constants";
import {
  CustomerDomainByCustomerId,
  CustomerId
} from "../../constants/customerConfig";
import Link from "../../components/Link";
import LifecycleStagePill from "../../components/LifecycleStagePill";
import { FADED_GRAY, SMALL_FONT_SIZE } from "../../constants/styles";
import { clearbitRevenueSortValue } from "../../constants/clearbit";
import { FEATURE_META } from "../../constants/feature";

import MetricChangeOverTime from "../../components/Metrics/MetricChangeOverTime";
import HealthScoreExplanation from "../../components/HealthScore/HealthScoreExplanation";

const MetricCard = styled(Card)`
  padding: 24px 26px 22px;
  flex-grow: 1;
  flex-basis: 33%;

  & + & {
    margin-left: 49px;
  }
`;
MetricCard.MainRow = styled(Row)`
  align-items: center;
  padding-top: 14px;
`;
MetricCard.Title = styled.div`
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #7e89a9;
  text-transform: uppercase;
`;
MetricCard.Metric = styled.div`
  font-size: 38px;
  line-height: 52px;
  color: #394455;
  flex-grow: 1;
`;
MetricCard.MetricDelta = styled.div`
  white-space: nowrap;
  margin-left: 9px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #1dbca5;
`;
MetricCard.Caption = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #475872;
`;
MetricCard.Sparkline = styled.div`
  padding-right: 14px;
`;

const TableRow = styled(Table.Row)`
  text-decoration: none;
  position: relative;

  & > div:not(.📦flx_1-5) > span {
    overflow: visible;
    min-width: 0;
  }

  .date {
    margin-left: 62px;
  }

  button {
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background-color: #f9fafb !important;

    button {
      opacity: 1;
      pointer-events: all;
    }
    .date {
      opacity: 1;
    }
  }
`;

const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
  NONE: null
};

const sortFunction = (a = "", b = "", direction) => {
  if (a === null && b !== null) {
    return 1;
  }
  if (b === null && a !== null) {
    return -1;
  }
  if (a === null && b === null) {
    return 0;
  }

  if (typeof a === "string") {
    if (a < b) {
      return direction === SORT_ORDER.ASC ? 1 : -1;
    } else if (a > b) {
      return direction === SORT_ORDER.ASC ? -1 : 1;
    } else {
      return 0;
    }
  }
  return direction === SORT_ORDER.ASC ? a - b : b - a;
};

const AvatarFallback = styled(Pane)`
  width: 24px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;
  height: 24px;
  flex-shrink: 0;
`;

const formatDollars = (dollars, fixed = 0) => {
  let formattedDollars = "";
  if (dollars >= 1000000) {
    formattedDollars = `$${
      Math.round(dollars / (1000000 / Math.pow(10, fixed))) /
      Math.pow(10, fixed)
    }M`;
  } else if (dollars >= 1000) {
    formattedDollars = `$${
      Math.round(dollars / (1000 / Math.pow(10, fixed))) / Math.pow(10, fixed)
    }K`;
  } else {
    formattedDollars = `$${dollars}`;
  }
  return formattedDollars;
};

const DimensionCell = ({ dimension, account, owners, userDomain, index }) => {
  const [hadAvatarError, setHadAvatarError] = useState(false);

  const domain = extractDomain(
    account.traits?.WEBSITE ||
      account.traits?.TRAITS_COMPANY_WEBSITE ||
      account.traits?.EMAIL ||
      account.traits?.DOMAIN ||
      ""
  );

  const accountMeta =
    account.traits && dimension.useTrait !== false ? account.traits : account;

  let displayValue = accountMeta[dimension.dimension];

  const addDefaultSrc = ev => {
    setHadAvatarError(true);
  };

  if (dimension.dimension === "name") {
    return (
      <TableTextCell flex={1.5}>
        <Pane display="flex" alignItems="center">
          {!hadAvatarError && (
            <CompanyLogo
              onError={addDefaultSrc}
              src={`https://logo.clearbit.com/www.${domain}`}
            />
          )}
          {hadAvatarError && (
            <AvatarFallback
              backgroundColor={COLOR_RANGE[index % COLOR_RANGE.length]}
            >
              {account.name[0]}
            </AvatarFallback>
          )}
          <Pane
            is="span"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            width="100%"
            minWidth="0"
            maxWidth="none"
            textAlign="left"
          >
            {he.decode(account.name)}
            {!!account.account_id && ` (${account.account_id})`}
          </Pane>
        </Pane>
      </TableTextCell>
    );
  }
  if (dimension.dimension === "_revenue") {
    if (!account.stats || !account.stats.MOST_RECENT_REVENUE_FORECAST) {
      return <TableTextCell>-</TableTextCell>;
    }

    const startOfQ = dateFns.startOfQuarter(Date.now());
    const LENGTH_QUARTER_IN_DAYS = 91;
    const daysSinceStartOfQ = dateFns.differenceInDays(Date.now(), startOfQ);
    const percentageComplete = daysSinceStartOfQ / LENGTH_QUARTER_IN_DAYS;

    const runrate =
      account.stats.MOST_RECENT_REVENUE_THIS_QUARTER / percentageComplete;

    return (
      <TableTextCell>
        {Math.round(
          100 * (runrate / account.stats.MOST_RECENT_REVENUE_FORECAST)
        )}
        %
      </TableTextCell>
    );
  }
  if (dimension.type === "old_health") {
    const timeSortedScores = [...(account.health_scores || [])]
      .sort((a, b) =>
        dateFns.compareDesc(
          dateFns.parseISO(a.timestamp),
          dateFns.parseISO(b.timestamp)
        )
      )
      .reduce((sum, curr, i, source) => {
        if (
          i === source.length - 1 ||
          !dateFns.isSameDay(
            dateFns.parseISO(curr.timestamp),
            dateFns.parseISO(source[i + 1].timestamp)
          )
        ) {
          return [...sum, curr];
        }

        return sum;
      }, []);

    const mostRecentHealthScoreMeta =
      timeSortedScores && timeSortedScores.length
        ? timeSortedScores[0]
        : { health_score: 0 };

    const mostRecentHealthScore = mostRecentHealthScoreMeta.health_score;

    const healthColor = getHealthBucket(mostRecentHealthScore).color;

    return (
      <TableTextCell isNumber position="relative">
        <Row justifyContent="center" alignItems="center" maxHeight={35}>
          <PriorityOverlayTrigger
            userDomain={userDomain}
            score_health={mostRecentHealthScore}
            healthMeta={mostRecentHealthScoreMeta}
            stats={account.stats}
          >
            <Row alignItems="center" justifyContent="center">
              {mostRecentHealthScore !== undefined &&
                mostRecentHealthScore !== null && (
                  <PriorityScore>
                    {Math.round(mostRecentHealthScore)}
                  </PriorityScore>
                )}
            </Row>
          </PriorityOverlayTrigger>
          <Pane transform="scale(0.4)" marginX={-25}>
            {timeSortedScores && (
              <Sparkline
                ariaLabel="Health score over time"
                width={140}
                height={70}
                data={[...timeSortedScores].slice(0, 10).reverse()}
                valueAccessor={({ health_score }) => health_score}
              >
                <LineSeries
                  showArea={false}
                  stroke={allColors[healthColor][7]}
                />
              </Sparkline>
            )}
          </Pane>
        </Row>
      </TableTextCell>
    );
  }

  if (dimension.type === "health") {
    const mostRecentHealthScoreMeta =
      account.health_scores && account.health_scores.length
        ? account.health_scores[0]
        : { [dimension.accessor]: 0 };

    let mostRecentHealthScore = mostRecentHealthScoreMeta[dimension.accessor];

    if (
      ["_health_score", "_blended_health_score"].includes(dimension.dimension)
    ) {
      if (!account.stats || !account.stats.MOST_RECENT_REVENUE_FORECAST) {
        mostRecentHealthScore = NaN;
      }
    }

    if (mostRecentHealthScore) {
      mostRecentHealthScore = 100 * mostRecentHealthScore;
    }
    const healthColor = getHealthBucket(mostRecentHealthScore).color;

    return (
      <TableTextCell isNumber position="relative">
        <Row justifyContent="center" alignItems="center">
          {["_non_revenue_health_score", "_blended_health_score"].includes(
            dimension.dimension
          ) && (
            <PriorityOverlayTrigger
              userDomain={userDomain}
              score_health={mostRecentHealthScore}
              stats={account.stats}
              healthMeta={mostRecentHealthScoreMeta}
            >
              <Row alignItems="center" justifyContent="center">
                {mostRecentHealthScore !== undefined &&
                  mostRecentHealthScore !== null && (
                    <PriorityScore>
                      {isNaN(Math.round(mostRecentHealthScore))
                        ? "-"
                        : Math.round(mostRecentHealthScore)}
                    </PriorityScore>
                  )}
              </Row>
            </PriorityOverlayTrigger>
          )}
          {["_health_score"].includes(dimension.dimension) && (
            <Row alignItems="center" justifyContent="center">
              {mostRecentHealthScore !== undefined &&
                mostRecentHealthScore !== null && (
                  <>
                    <PriorityScore>
                      {isNaN(Math.round(mostRecentHealthScore))
                        ? "-"
                        : Math.round(mostRecentHealthScore)}
                    </PriorityScore>
                  </>
                )}
            </Row>
          )}

          {mostRecentHealthScore !== undefined &&
            mostRecentHealthScore !== null &&
            !isNaN(Math.round(mostRecentHealthScore)) && (
              <Pane transform="scale(0.4)" marginX={-25}>
                <Sparkline
                  ariaLabel="All activity"
                  width={140}
                  height={70}
                  data={[...account.health_scores].slice(0, 10).reverse()}
                  valueAccessor={datum => datum[dimension.accessor]}
                >
                  <LineSeries
                    showArea={false}
                    stroke={allColors[healthColor][7]}
                  />
                </Sparkline>
              </Pane>
            )}
        </Row>
      </TableTextCell>
    );
  }
  if (dimension.type === "metricChangeOverTimeRatio") {
    const changeRatio = account.stats[dimension.dimension];
    const numOfTotalEvents = account.stats[dimension.numOfTotalEvents];

    return (
      <TableTextCell>
        <MetricChangeOverTime
          changeRatio={changeRatio}
          numOfTotalEvents={numOfTotalEvents}
        />
      </TableTextCell>
    );
  }

  if (dimension.type === "stats") {
    let dimensionValue;
    if (dimension.selector) {
      dimensionValue = dimension.selector(account.stats[dimension.dimension]);
    } else {
      dimensionValue = account.stats[dimension.dimension];
    }

    let statDate, statMeta;
    if (dimension.dimension.indexOf("MOST_RECENT_") === 0) {
      const baseStatName = dimension.dimension.slice(12);
      const allStatKey = "ALL_" + baseStatName;
      if (account.stats && account.stats[allStatKey]) {
        const m =
          typeof account.stats[allStatKey] === "string"
            ? JSON.parse(account.stats[allStatKey])
            : account.stats[allStatKey];
        if (!m.length) {
          return <TableTextCell>-</TableTextCell>;
        }
        const dat = dateFns.fromUnixTime(
          Math.round(m[m.length - 1].timestamp / 1000)
        );

        statDate = dateFns.format(dat, "yyyy/MM/dd");
      }
      const averageStatKey = "AVERAGE_" + baseStatName;
      if (account.stats && account.stats[averageStatKey] !== undefined) {
        const m = account.stats[averageStatKey];
        statMeta = `Avg. ${Math.round(m * 10) / 10}`;
      }
    }

    return (
      <TableTextCell>
        {account.stats &&
        dimensionValue !== undefined &&
        dimensionValue !== null ? (
          <Pane display="flex" alignItems="center" justifyContent="center">
            {dimension.prefix || ""}
            {dimension.subtype === "dollars"
              ? formatDollars(Math.round(dimensionValue))
              : Math.round(dimensionValue)}

            {dimension.sparklineDimension &&
              account.stats[dimension.sparklineDimension] && (
                <Pane
                  height={47}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Pane transform="scale(0.4)" marginX={-25}>
                    <Sparkline
                      ariaLabel="Metric over time"
                      width={140}
                      height={70}
                      data={getTimeSeriesForTimeRange(
                        account.stats[dimension.sparklineDimension],
                        30
                      )}
                      valueAccessor={({ COUNT }) => COUNT}
                    >
                      <LineSeries
                        showArea={false}
                        stroke={
                          dimension.trendDimension
                            ? allColors[
                                getHealthColorByTrend(
                                  account.stats[dimension.trendDimension]
                                )
                              ][7]
                            : allColors.grape[7]
                        }
                      />
                    </Sparkline>
                  </Pane>
                </Pane>
              )}

            <Pane
              is="span"
              className="date"
              position="absolute"
              marginLeft={8}
              marginTop={!!statMeta ? -9 : 1}
              fontSize={11}
              opacity={0}
              fontWeight={600}
              transition="opacity 0.2s ease"
            >
              <Pane is="span" opacity={0.6}>
                {statDate}
              </Pane>
            </Pane>
            {!!statMeta && (
              <Pane
                is="span"
                className="date"
                position="absolute"
                marginLeft={8}
                marginTop={10}
                fontSize={11}
                opacity={0}
                fontWeight={600}
                transition="opacity 0.2s ease"
              >
                <Pane is="span" opacity={0.9}>
                  {statMeta}
                </Pane>
              </Pane>
            )}
          </Pane>
        ) : (
          "-"
        )}
      </TableTextCell>
    );
  }
  if (dimension.type === "lifecyclestage") {
    return (
      <TableTextCell>
        <LifecycleStagePill stage={account[dimension.dimension]} />
      </TableTextCell>
    );
  }
  if (dimension.type === "_source") {
    const isIntercomOnly = Object.keys(account.traits).length === 1;
    return (
      <TableTextCell>
        {isIntercomOnly ? "Intercom" : "Salesforce"}
      </TableTextCell>
    );
  }
  if (dimension.type === "_intercom_custom_origin") {
    return (
      <TableTextCell>
        <Pane fontWeight={700} fontSize={12}>
          {account.traits.INTERCOM && account.traits.INTERCOM.CUSTOM_ORIGIN}
        </Pane>
      </TableTextCell>
    );
  }
  if (dimension.type === "_intercom_custom_status") {
    return (
      <TableTextCell>
        <Pane fontWeight={700} fontSize={12}>
          {account.traits.INTERCOM && account.traits.INTERCOM.CUSTOM_STATUS}
        </Pane>
      </TableTextCell>
    );
  }
  if (dimension.dimension === "users") {
    return (
      <TableTextCell>{account.users ? account.users.length : 0}</TableTextCell>
    );
  }
  if (dimension.type === "cents") {
    return (
      <TableTextCell isNumber>
        ${Math.round(account[dimension.dimension] / 100)}
      </TableTextCell>
    );
  }
  if (account.traits && dimension.dimension === "assigned_csms") {
    const ownerId = account.traits.OWNER_ID;
    const owner = owners.find(owner => owner.owner_id === ownerId);
    return (
      <TableTextCell>
        {owner && owner.traits && owner.traits.NAME}
      </TableTextCell>
    );
  }
  if (dimension.dimension === "assigned_msm") {
    const ownerId = account.traits.MERCHANT_SUCCESS_MANAGER_C;
    const owner = owners.find(owner => owner.owner_id === ownerId);
    return <TableTextCell>{owner.traits.NAME}</TableTextCell>;
  }
  if (dimension.type === "date") {
    return (
      <TableTextCell
        title={
          accountMeta[dimension.dimension]
            ? dateFns.format(
                dateFns.parseISO(accountMeta[dimension.dimension]),
                "yyyy/MM/dd"
              )
            : "N/A"
        }
      >
        {accountMeta[dimension.dimension]
          ? dateFns.formatDistanceToNow(
              dateFns.parseISO(accountMeta[dimension.dimension])
            )
          : "-"}
      </TableTextCell>
    );
  }
  if (dimension.type === "stat") {
    let stat = !account.stats
      ? 0
      : account.stats
          .filter(stat =>
            dimension.matches.some(match =>
              Object.keys(match).every(key => match[key] === stat[key])
            )
          )
          .reduce((sum, curr) => curr[dimension.aggregate] + sum, 0);

    if (dimension.compare) {
      let comparisonStat = !account.stats
        ? 0
        : account.stats
            .filter(stat =>
              dimension.matches.some(match =>
                Object.keys(match).every(key => match[key] === stat[key])
              )
            )
            .reduce((sum, curr) => curr[dimension.compare] + sum, 0);
      if (!comparisonStat) {
        stat = "-";
      } else {
        stat = Math.round((100 * stat) / comparisonStat) + "%";
      }
    }

    return <TableTextCell>{stat}</TableTextCell>;
  }

  if (dimension.type === "dollars") {
    const dollars = accountMeta[dimension.dimension];
    let formattedDollars = "-";
    if (dollars >= 1000000) {
      formattedDollars = `$${Math.round(dollars / 100000) / 10}M`;
    } else if (dollars >= 1000) {
      formattedDollars = `$${Math.round(dollars / 100) / 10}K`;
    } else if (!!dollars) {
      formattedDollars = `$${dollars}`;
    }

    return <TableTextCell>{formattedDollars}</TableTextCell>;
  }
  if (dimension.type === "float") {
    if (displayValue) {
      displayValue = displayValue.toFixed(dimension.digits);
    }
  }

  return (
    <TableTextCell>
      <Pane display="flex" alignItems="center" justifyContent="center">
        <Pane>
          {dimension.prefix || ""}
          {displayValue !== null && displayValue !== undefined
            ? displayValue
            : "-"}
          {dimension.suffix || ""}
        </Pane>

        {dimension.sparklineDimension &&
          accountMeta[dimension.sparklineDimension] && (
            <Pane
              height={47}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Pane transform="scale(0.4)" marginX={-25}>
                <Sparkline
                  ariaLabel="Metric over time"
                  width={140}
                  height={70}
                  data={getTimeSeriesForTimeRange(
                    accountMeta[dimension.sparklineDimension],
                    30
                  )}
                  valueAccessor={({ COUNT }) => COUNT}
                >
                  <LineSeries
                    showArea={false}
                    stroke={
                      dimension.trendDimension
                        ? allColors[
                            getHealthColorByTrend(
                              accountMeta[dimension.trendDimension]
                            )
                          ][7]
                        : allColors.grape[7]
                    }
                  />
                </Sparkline>
              </Pane>
            </Pane>
          )}
      </Pane>
    </TableTextCell>
  );
};

const getHealthColorByTrend = trend => (trend > 1 ? "green" : "red");

const getTimeSeriesForTimeRange = (timeSeries, days) => {
  const startOfRangeDate = dateFns.subDays(Date.now(), days);
  return timeSeries
    .filter(t =>
      dateFns.isAfter(dateFns.parseISO(t.TIMESTAMP), startOfRangeDate)
    )
    .sort((a, b) => {
      return dateFns.compareAsc(
        dateFns.parseISO(a.TIMESTAMP),
        dateFns.parseISO(b.TIMESTAMP)
      );
    });
};

const INITIAL_SORT_DIMENSIONS = {
  current_mrr: SORT_ORDER.DESC
};

const INITIAL_FILTER_DIMENSIONS = [
  { dimension: "name", filteredAttributes: [] },
  { dimension: "assigned_csms", filteredAttributes: [] },
  { dimension: "current_stage", filteredAttributes: [] },
  { dimension: "current_mrr", filteredAttributes: [] },
  { dimension: "join_date", filteredAttributes: [] }
];

const getObjPropertyBySelector = (obj, selector) => {
  const parts = (selector || "").split(".");
  let item = obj;

  for (let i = 0; i < parts.length; i++) {
    item = item[parts[i]];
  }
  return item;
};

const getHealthBucket = healthScore => {
  if (healthScore < 60) {
    return { color: "red", order: 1 };
  }
  if (healthScore < 80) {
    return { color: "yellow", order: 2 };
  }
  return { color: "green", order: 3 };
};

const convertOldFiltersToNewFilters = oldFilters =>
  oldFilters.map(o => ({ dimension: o, filteredAttributes: [] }));

function Accounts({
  selectedCsmEmail,
  accounts,
  accountsLastUpdateTime,
  allAccountsLength,
  owners,
  userDomain,
  Nav,
  user
}) {
  const [sortDimensions, setSortDimensions] = useState();
  const [filterDimensions, setFilterDimensions] = useState();

  const getFilters = async () => {
    const existingData = await getLocalData(
      "userPreferences",
      "dashboardFilters",
      "dashboardFilters"
    );
    if (existingData) {
      if (
        !!existingData.value.filterDimensions &&
        existingData.value.filterDimensions.length &&
        typeof existingData.value.filterDimensions[0] === "string"
      ) {
        setFilterDimensions(
          convertOldFiltersToNewFilters(existingData.value.filterDimensions)
        );
      } else {
        setFilterDimensions(existingData.value.filterDimensions);
      }
      setSortDimensions(existingData.value.sortDimensions);
    } else {
      setSortDimensions(INITIAL_SORT_DIMENSIONS);
      if (userDomain === "shipbob.com") {
        setFilterDimensions([
          { dimension: "name", filteredAttributes: [] },
          { dimension: "_blended_health_score", filteredAttributes: [] },
          { dimension: "_health_score", filteredAttributes: [] },
          { dimension: "_revenue", filteredAttributes: [] },
          { dimension: "MOST_RECENT_REVENUE_FORECAST", filteredAttributes: [] }
        ]);
      } else if (userDomain === "upflow.io") {
        setFilterDimensions([
          { dimension: "name", filteredAttributes: [] },
          { dimension: "_health_score", filteredAttributes: [] },
          { dimension: "TO_CUSTOMER_DATE_C", filteredAttributes: [] },
          { dimension: "ANNUAL_REVENUE", filteredAttributes: [] },
          { dimension: "MOST_RECENT_NPS", filteredAttributes: [] },
          { dimension: "COUNT_ACTIVE_USERS_LAST_WEEK", filteredAttributes: [] }
        ]);
      } else if (userDomain === "eventgeek.com") {
        setFilterDimensions([
          { dimension: "name", filteredAttributes: [] },
          { dimension: "TYPE", filteredAttributes: [] },
          { dimension: "STAGE_NAME", filteredAttributes: [] },
          { dimension: "ORG_SUBSCRIPTION_PLAN", filteredAttributes: [] },
          { dimension: "ORG_SUBSCRIPTION_EXPIRES_AT", filteredAttributes: [] },
          { dimension: "NUM_SESSIONS_LAST_14", filteredAttributes: [] },
          { dimension: "DAUS", filteredAttributes: [] }
        ]);
      } else if (userDomain === "rossintelligence.com") {
        setFilterDimensions([
          { dimension: "name", filteredAttributes: [] },
          {
            dimension: "COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE",
            filteredAttributes: []
          },
          { dimension: "COMPANY_METRICS_EMPLOYEES", filteredAttributes: [] },
          { dimension: "TIER", filteredAttributes: [] },
          { dimension: "NUM_USERS", filteredAttributes: [] },
          { dimension: "NUM_SESSIONS_LAST_7", filteredAttributes: [] },
          { dimension: "NUM_SESSIONS_WEEK_RATIO", filteredAttributes: [] },
          { dimension: "NUM_EVENTS_LAST_7", filteredAttributes: [] }
        ]);
      } else if (
        userDomain === CustomerDomainByCustomerId[CustomerId.Instabug]
      ) {
        setFilterDimensions([
          { dimension: "name", filteredAttributes: [] },
          {
            dimension: "_health_score",
            filteredAttributes: []
          },
          { dimension: "COMPANY_METRICS_EMPLOYEES", filteredAttributes: [] },
          {
            dimension: "COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE",
            filteredAttributes: []
          },
          { dimension: "NUM_BUG_EVENTS_WEEK_RATIO", filteredAttributes: [] },
          { dimension: "NUM_BUG_EVENTS_LAST_7", filteredAttributes: [] },
          {
            dimension: "NUM_CRASH_REPORT_EVENTS_WEEK_RATIO",
            filteredAttributes: []
          },
          { dimension: "NUM_SURVEY_EVENTS_WEEK_RATIO", filteredAttributes: [] },
          {
            dimension: "NUM_UPGRADE_INDICATOR_EVENTS_WEEK_RATIO",
            filteredAttributes: []
          }
        ]);
      } else {
        setFilterDimensions(INITIAL_FILTER_DIMENSIONS);
      }
    }
  };

  useEffect(() => {
    getFilters();
    if (window.analytics) {
      window.analytics.page("Accounts");
      window.analytics.track("Page loaded", {
        name: "Accounts",
        url: window.location.pathname,
        email: user.email,
        customerId: domainToCustomerId[userDomain]
      });
    }
  }, []);

  useEffect(() => {
    if (sortDimensions && filterDimensions) {
      setLocalData("userPreferences", "dashboardFilters", {
        sortDimensions,
        filterDimensions
      });
    }
  }, [sortDimensions, filterDimensions]);

  window.accounts = accounts;
  window.owners = owners;

  const ACCOUNTS_WITHOUT_OWNERS = [
    "instabug.com",
    "eventgeek.com",
    "rossintelligence.com"
  ];

  if (
    !accounts ||
    !accounts.length ||
    !owners ||
    (!owners.length && !ACCOUNTS_WITHOUT_OWNERS.includes(userDomain)) ||
    !sortDimensions ||
    !filterDimensions
  ) {
    console.log(
      !accounts,
      !accounts.length,
      !owners,
      !owners.length,
      !sortDimensions,
      !filterDimensions
    );
    return (
      <>
        <Nav
          pageTitle="Customer Dashboard"
          lastUpdateTime={accountsLastUpdateTime}
        />
        <Main>
          <Card>
            <Table>
              <TableHead
                paddingX={17}
                backgroundColor="white"
                position="relative"
              >
                <Pane
                  height={16}
                  backgroundColor="whitesmoke"
                  width="20%"
                  marginLeft={12}
                  borderRadius={4}
                ></Pane>
              </TableHead>
              <Table.Body overflow="visible">
                {new Array(25).fill().map((_, i) => (
                  <TableRow key={i}>
                    <Pane
                      height={24}
                      backgroundColor="whitesmoke"
                      width={24}
                      borderRadius={4}
                      alignSelf="center"
                      marginLeft={29}
                      marginRight={21}
                    ></Pane>
                    <Pane
                      height={8}
                      backgroundColor="whitesmoke"
                      borderRadius={4}
                      marginRight={41}
                      alignSelf="center"
                      width={`${70 + Math.round(Math.random() * 30)}%`}
                    ></Pane>
                  </TableRow>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </Main>
      </>
    );
  }

  console.log(
    selectedCsmEmail,
    accounts,
    owners,
    userDomain,
    sortDimensions,
    filterDimensions
  );

  let dimensions;
  if (userDomain === "h1insights.com") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Owner",
        hidden: selectedCsmEmail !== "",
        dimension: "assigned_csms"
      },
      {
        label: "Health",
        dimension: "_health_score",
        type: "old_health"
      },
      {
        label: "Customer For",
        dimension: "CREATED_DATE",
        type: "date"
      },
      {
        label: "# Users",
        dimension: "users"
      },
      {
        label: "Searches (7 days)",
        dimension: "searches_last_7",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_7"
      },
      {
        label: "Searches (7 day change)",
        dimension: "searches_last_7_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_7",
        compare: "prev_7"
      },
      {
        label: "Searches (14 days)",
        dimension: "searches_last_14",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_14"
      },
      {
        label: "Searches (14 day change)",
        dimension: "searches_last_14_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_14",
        compare: "prev_14"
      },
      {
        label: "Searches (30 days)",
        dimension: "searches_last_30",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_30"
      },
      {
        label: "Searches (30 day change)",
        dimension: "searches_last_30_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "last_30",
        compare: "prev_30"
      },
      {
        label: "Searches (All-time)",
        dimension: "searches_all_time",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "Search", email: null },
          { type: "SEGMENT:TRACK", event: "search", email: null }
        ],
        aggregate: "all_time"
      },
      {
        label: "Profile Views (7 days)",
        dimension: "profile_views_last_7",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_7"
      },
      {
        label: "Profile Views (7 day change)",
        dimension: "profile_views_last_7_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_7",
        compare: "prev_7"
      },
      {
        label: "Profile Views (14 days)",
        dimension: "profile_views_last_14",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_14"
      },
      {
        label: "Profile Views (14 day change)",
        dimension: "profile_views_last_14_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_14",
        compare: "prev_14"
      },
      {
        label: "Profile Views (30 days)",
        dimension: "profile_views_last_30",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_30"
      },
      {
        label: "Profile Views (30 day change)",
        dimension: "profile_views_last_30_change",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "last_30",
        compare: "prev_30"
      },
      {
        label: "Profile Views (All-time)",
        dimension: "profile_views_all_time",
        type: "stat",
        matches: [
          { type: "HEAP:TRACK", event: "ViewProfile", email: null },
          { type: "SEGMENT:TRACK", event: "profile.view", email: null }
        ],
        aggregate: "all_time"
      },

      {
        label: "Any App Event (7 day change)",
        dimension: "any_app_event_last_7_change",
        type: "stat",
        matches: [{ type: "SEGMENT:TRACK", email: null }],
        aggregate: "last_7",
        compare: "prev_7"
      },
      {
        label: "Any App Event (14 day change)",
        dimension: "any_app_event_last_14_change",
        type: "stat",
        matches: [{ type: "SEGMENT:TRACK", email: null }],
        aggregate: "last_14",
        compare: "prev_14"
      },
      {
        label: "Any App Event (30 day change)",
        dimension: "any_app_event_last_30_change",
        type: "stat",
        matches: [{ type: "SEGMENT:TRACK", email: null }],
        aggregate: "last_30",
        compare: "prev_30"
      }
    ];
  } else if (userDomain === "upflow.io") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Health",
        dimension: "_health_score",
        type: "old_health"
      },
      {
        label: "Source",
        dimension: "SOURCE",
        type: "_source"
      },
      {
        label: "Intercom Custom Origin",
        dimension: "SOURCE_CUSTOM_ORIGIN",
        type: "_intercom_custom_origin"
      },
      {
        label: "Intercom Custom Status",
        dimension: "SOURCE_CUSTOM_STATUS",
        type: "_intercom_custom_status",
        options: [
          { label: "Active", value: "ACTIVE" },
          { label: "Trial", value: "TRIAL" },
          { label: "Churned", value: "CHURNED" },
          { label: "Onboarding", value: "ONBOARDING" }
        ],
        selector: "INTERCOM.CUSTOM_STATUS"
      },
      {
        label: "Customer For",
        dimension: "TO_CUSTOMER_DATE_C",
        type: "date"
      },
      {
        label: "Annual Revenue",
        dimension: "ANNUAL_REVENUE",
        prefix: "$",
        type: "dollars"
      },
      {
        label: "NPS",
        dimension: "MOST_RECENT_NPS",
        type: "stats"
      },
      {
        label: "Active Users Last Week",
        dimension: "COUNT_ACTIVE_USERS_LAST_WEEK",
        type: "stats"
      },
      {
        label: "Days Since Last Key Event",
        dimension: "DAYS_SINCE_LAST_KEY_EVENT",
        type: "stats"
      }
    ];
  } else if (userDomain === "shipbob.com") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Blended Health",
        dimension: "_blended_health_score",
        type: "health",
        accessor: "TOTAL_NORMALIZED_SCORE"
      },
      {
        label: "Revenue Health",
        dimension: "_health_score",
        type: "health",
        accessor: "REVENUE_NORMALIZED_SCORE"
      },
      {
        label: "Engagement Health",
        dimension: "_non_revenue_health_score",
        type: "health",
        accessor: "SENTIMENT_NORMALIZED_SCORE"
      },
      {
        label: "Merchant Success Manager",
        hidden: selectedCsmEmail !== "",
        dimension: "assigned_msm"
      },
      {
        label: "First SIE Activity",
        dimension: "FIRST_SIE_ACTIVITY_DATE_C",
        type: "date"
      },
      {
        label: "First Shipment",
        dimension: "FIRST_SHIPMENT_DATE_C",
        type: "date"
      },
      {
        label: "Revenue this quarter",
        dimension: "MOST_RECENT_REVENUE_THIS_QUARTER",
        type: "stats",
        prefix: "$"
      },
      {
        label: "% Revenue forecast",
        dimension: "_revenue"
      },
      {
        label: "Revenue forecast",
        dimension: "MOST_RECENT_REVENUE_FORECAST",
        type: "stats",
        subtype: "dollars"
      },
      {
        label: "Lifetime revenue",
        dimension: "MOST_RECENT_LIFETIME_REVENUE",
        type: "stats",
        subtype: "dollars"
      },
      {
        label: "Revenue last quarter",
        dimension: "MOST_RECENT_REVENUE_LAST_QUARTER",
        type: "stats",
        prefix: "$"
      },
      {
        label: "Shipments this quarter",
        dimension: "MOST_RECENT_SHIPMENTS_THIS_QUARTER",
        type: "stats"
      },
      {
        label: "NPS",
        dimension: "MOST_RECENT_NPS",
        type: "stats"
      },
      {
        label: "ShipBob Sat.",
        dimension: "MOST_RECENT_SB_SAT",
        type: "stats"
      },
      {
        label: "Merchant Care Sat.",
        dimension: "MOST_RECENT_MC_SAT",
        type: "stats"
      }
    ];
  } else if (userDomain === "quantif.ai") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Last activity",
        dimension: "most_recent_activity",
        useTrait: false,
        type: "date"
      }
    ];
  } else if (userDomain === "eventgeek.com") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Type",
        dimension: "TYPE",
        options: [
          { label: "Deal", value: "DEAL" },
          { label: "Health", value: "HEALTH" }
        ],
        selector: "TYPE"
      },
      {
        label: "Stage",
        dimension: "STAGE_NAME"
      },
      {
        label: "Annual Revenue",
        dimension: "COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE"
      },
      {
        label: "Employees",
        dimension: "COMPANY_METRICS_EMPLOYEES"
      },

      {
        label: "Plan",
        dimension: "ORG_SUBSCRIPTION_PLAN"
      },
      {
        label: "Renewal Date",
        dimension: "ORG_SUBSCRIPTION_EXPIRES_AT"
      },
      {
        label: "Sessions (All-Time)",
        dimension: "NUM_SESSIONS",
        type: "stats",
        sparklineDimension: "SESSIONS",
        trendDimension: "NUM_SESSIONS_RATIO"
      },
      {
        label: "Sessions (Last 14 days)",
        dimension: "NUM_SESSIONS_LAST_14",
        type: "stats",
        sparklineDimension: "SESSIONS",
        trendDimension: "NUM_SESSIONS_RATIO"
      },
      {
        label: "Daily Active Users",
        dimension: "DAUS",
        type: "stats",
        selector: dimension =>
          (
            dimension.sort((a, b) =>
              dateFns.compareAsc(
                dateFns.parseISO(a.TIMESTAMP),
                dateFns.parseISO(b.TIMESTAMP)
              )
            )[0] || {}
          ).COUNT,
        sparklineDimension: "DAUS",
        trendDimension: "NUM_USERS_RATIO"
      }
    ];
  } else if (userDomain === "rossintelligence.com") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Annual Revenue",
        dimension: "COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE"
      },
      {
        label: "Employees",
        dimension: "COMPANY_METRICS_EMPLOYEES"
      },
      {
        label: "Tier",
        dimension: "TIER"
      },
      {
        label: "Users",
        dimension: "NUM_USERS"
      },
      {
        label: "Events (total)",
        dimension: "NUM_EVENTS",
        sparklineDimension: "DAILY_EVENT_COUNTS"
      },
      {
        label: "Events (7 days)",
        dimension: "NUM_EVENTS_LAST_7",
        sparklineDimension: "DAILY_EVENT_COUNTS",
        trendDimension: "NUM_EVENTS_WEEK_RATIO"
      },
      {
        label: "Events (avg. daily)",
        dimension: "NUM_EVENTS_PER_DAY",
        type: "float",
        digits: 1
      },
      {
        label: "Events (avg. weekly)",
        dimension: "NUM_EVENTS_PER_WEEK",
        type: "float",
        digits: 1
      },
      {
        label: "Successful queries (7 days)",
        dimension: "NUM_QUERY_SUCCESS_LAST_7",
        sparklineDimension: "DAILY_QUERY_SUCCESS_COUNTS",
        trendDimension: "NUM_QUERY_SUCCESS_WEEK_RATIO"
      },
      {
        label: "Documents generated (7 days)",
        dimension: "NUM_DOCUMENT_GENERATE_LAST_7"
      },
      {
        label: "Documents opened (7 days)",
        dimension: "NUM_DOCUMENT_OPEN_LAST_7",
        sparklineDimension: "DAILY_DOCUMENT_OPEN_COUNTS",
        trendDimension: "NUM_DOCUMENT_OPEN_WEEK_RATIO"
      },
      {
        label: "Sessions (total)",
        dimension: "NUM_SESSIONS",
        sparklineDimension: "DAILY_SESSION_COUNTS"
      },
      {
        label: "Sessions (7 days)",
        dimension: "NUM_SESSIONS_LAST_7",
        sparklineDimension: "DAILY_SESSION_COUNTS",
        trendDimension: "NUM_SESSIONS_WEEK_RATIO"
      },
      {
        label: "Sessions (avg. daily)",
        dimension: "NUM_SESSIONS_PER_DAY",
        type: "float",
        digits: 1
      },
      {
        label: "Sessions (avg. weekly)",
        dimension: "NUM_SESSIONS_PER_WEEK",
        type: "float",
        digits: 1
      }
    ];
  } else if (userDomain === "instabug.com") {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Health Score",
        dimension: "_health_score",
        type: "old_health"
      },
      {
        label: "Account ID",
        dimension: "GROUP_ID",
        type: "stats"
      },
      {
        label: "Num. of Employees",
        dimension: "COMPANY_METRICS_EMPLOYEES"
      },
      {
        label: "Company Revenue",
        dimension: "COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE",
        type: "clearbitRevenue"
      },
      {
        label: "Company Size",
        dimension: "COMPANY_COMPUTED_SIZE"
      },
      {
        label: "Industry",
        dimension: "COMPANY_CATEGORY_INDUSTRY"
      },
      {
        label: "Users",
        dimension: "NUM_USERS",
        type: "stats"
      },
      {
        label: "Signups (7 days)",
        dimension: "NUM_SIGNUPS_LAST_7",
        type: "stats"
      },
      {
        label: "Bug Reports Active Usage Trend (Weekly)",
        dimension: "NUM_BUG_EVENTS_WEEK_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_BUG_EVENTS_LAST_7"
      },
      {
        label: "Bug Reports Active Usage (Last 7)",
        dimension: "NUM_BUG_EVENTS_LAST_7",
        type: "stats"
      },
      {
        label: "Bug Reports Active Usage Trend (Monthly)",
        dimension: "NUM_BUG_EVENTS_MONTH_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_BUG_EVENTS_LAST_30"
      },
      {
        label: "Survey Active Usage Trend (Weekly)",
        dimension: "NUM_SURVEY_EVENTS_WEEK_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_SURVEY_EVENTS_LAST_7"
      },
      {
        label: "Survey Active Usage Trend (Monthly)",
        dimension: "NUM_SURVEY_EVENTS_MONTH_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_SURVEY_EVENTS_LAST_30"
      },
      {
        label: "Crash Reports Active Usage (7 days)",
        dimension: "NUM_CRASH_REPORT_EVENTS_LAST_7",
        type: "stats"
      },
      {
        label: "Crash Reports Active Usage Trend (Weekly)",
        dimension: "NUM_CRASH_REPORT_EVENTS_WEEK_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_CRASH_REPORT_EVENTS_LAST_7"
      },
      {
        label: "Crash Reports Active Usage Trend (Monthly)",
        dimension: "NUM_CRASH_REPORT_EVENTS_MONTH_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_CRASH_REPORT_EVENTS_LAST_30"
      },
      {
        label: "Account Threshold Exceeded Trend (Weekly)",
        dimension: "NUM_UPGRADE_INDICATOR_EVENTS_WEEK_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_UPGRADE_INDICATOR_EVENTS_LAST_7"
      },
      {
        label: "Account Threshold Exceeded Trend (Monthly)",
        dimension: "NUM_UPGRADE_INDICATOR_EVENTS_MONTH_RATIO",
        type: "metricChangeOverTimeRatio",
        numOfTotalEvents: "NUM_UPGRADE_INDICATOR_EVENTS_LAST_30"
      },
      {
        label: "Account Growth Events (7 days)",
        dimension: "NUM_ACCOUNT_GROWTH_INDICATORS_LAST_7",
        type: "stats"
      }
    ];
  } else {
    dimensions = [
      {
        label: `Company`,
        style: {
          justifyContent: "flex-start",
          flex: 1.5
        },
        dimension: "name"
      },
      {
        label: "Owner",
        hidden: selectedCsmEmail !== "",
        dimension: "assigned_csms"
      },
      {
        label: "Lifecycle Stage",
        dimension: "current_stage",
        type: "lifecyclestage"
      },
      {
        label: "MRR",
        dimension: "current_mrr",
        type: "cents"
      },
      {
        label: "Tier",
        dimension: "tier"
      },
      {
        label: "Customer For",
        dimension: "join_date",
        type: "date"
      },
      {
        label: "Renewal Date",
        dimension: "renewal_date",
        type: "date"
      },
      {
        label: "Last Activity",
        dimension: "modify_time",
        type: "date"
      }
    ];
  }

  const activeAccounts = (!owners.length
    ? accounts
    : accounts.filter((account, i, allAccounts) => {
        const owner = owners.find(owner => {
          if (account.traits) {
            if (userDomain === "shipbob.com") {
              return (
                owner.owner_id === account.traits.MERCHANT_SUCCESS_MANAGER_C
              );
            }
            return owner.owner_id === account.traits.OWNER_ID;
          }
          return account.assigned_csms.includes(owner.owner_id);
        });

        if (!owner && !["upflow.io", "quantif.ai"].includes(userDomain)) {
          console.log("bad owner", account);
          return false;
        }
        if (selectedCsmEmail) {
          console.log("filtering on", selectedCsmEmail);
          return owner.traits.EMAIL === selectedCsmEmail;
        }

        if (userDomain === "upflow.io") {
          if (!!account.traits.DETAILED_REASON_TO_DISQUALIFY_C) {
            return false;
          }
        }

        if (!account.stats && !["quantif.ai"].includes(userDomain)) {
          return false;
        }

        if (userDomain === "h1insights.com") {
          return [
            "ariel.katz@h1insights.com",
            "katie.chase@h1insights.com",
            "emma.schroeter@h1insights.com"
          ].includes(owner.traits.EMAIL);
        } else {
          return true;
        }
      })
  ).filter(
    account =>
      !["upflow.io", "eventgeek.com"].includes(userDomain) ||
      filterDimensions.every(f => {
        if (!f.filteredAttributes.length) {
          return true;
        }

        const dimension = dimensions.find(di => di.dimension === f.dimension);
        if (!dimension) {
          return true;
        }

        const value = getObjPropertyBySelector(
          account.traits,
          dimension.selector
        );

        return !!f.filteredAttributes.find(a => value === a);
      })
  );

  const visibleSortDimensions = Object.keys(sortDimensions).reduce(
    (sum, curr) => {
      if (
        !filterDimensions.find(f => f.dimension === curr) ||
        !dimensions.map(di => di.dimension).includes(curr)
      ) {
        return sum;
      }
      return { ...sum, [curr]: sortDimensions[curr] };
    },
    {}
  );

  return (
    <>
      <Helmet defer={false} title={`All Accounts | Quantifai`}>
        <link rel="shortcut icon" href="/favicon-96x96.png" />
      </Helmet>
      <Nav
        pageTitle="Customer Dashboard"
        lastUpdateTime={accountsLastUpdateTime}
      />

      <Main>
        <Card>
          <Table>
            <TableHead
              paddingX={17}
              backgroundColor="white"
              position="relative"
            >
              {dimensions
                .filter(
                  di =>
                    !di.hidden &&
                    filterDimensions.find(f => f.dimension === di.dimension)
                )
                .map(di => {
                  const sortedDimensionOrder = sortDimensions[di.dimension];

                  return (
                    <TableTextHeaderCell
                      key={di.dimension}
                      {...(di.style || {})}
                    >
                      <Pane
                        cursor="pointer"
                        display="flex"
                        position="relative"
                        onClick={() => {
                          if (sortedDimensionOrder) {
                            if (sortedDimensionOrder === "DESC") {
                              setSortDimensions({
                                ...sortDimensions,
                                [di.dimension]: SORT_ORDER.ASC
                              });
                            } else {
                              setSortDimensions(
                                Object.keys(sortDimensions).reduce(
                                  (sum, curr) => {
                                    if (curr !== di.dimension) {
                                      return {
                                        ...sum,
                                        [curr]: sortDimensions[curr]
                                      };
                                    }
                                    return sum;
                                  },
                                  {}
                                )
                              );
                            }
                          } else {
                            setSortDimensions({
                              ...sortDimensions,
                              [di.dimension]: SORT_ORDER.DESC
                            });
                          }
                        }}
                      >
                        {di.options && (
                          <Pane
                            className={`hover-hidden ${
                              filterDimensions.find(
                                f => f.dimension === di.dimension
                              ).filteredAttributes.length
                                ? "visible"
                                : ""
                            }`}
                            onClick={e => e.stopPropagation()}
                          >
                            <SelectMenu
                              isMultiSelect
                              title={`${di.label} filter`}
                              position="bottom-left"
                              options={di.options}
                              selected={
                                filterDimensions.find(
                                  f => f.dimension === di.dimension
                                ).filteredAttributes
                              }
                              onSelect={({ value }) =>
                                setFilterDimensions(
                                  filterDimensions.map(f =>
                                    f.dimension !== di.dimension
                                      ? f
                                      : {
                                          ...f,
                                          filteredAttributes: [
                                            ...f.filteredAttributes,
                                            value
                                          ]
                                        }
                                  )
                                )
                              }
                              onDeselect={({ value }) => {
                                setFilterDimensions(
                                  filterDimensions.map(f =>
                                    f.dimension !== di.dimension
                                      ? f
                                      : {
                                          ...f,
                                          filteredAttributes: f.filteredAttributes.filter(
                                            a => a !== value
                                          )
                                        }
                                  )
                                );
                              }}
                            >
                              <IconButton
                                icon="filter"
                                height={24}
                                appearance={
                                  filterDimensions.find(
                                    f => f.dimension === di.dimension
                                  ).filteredAttributes.length
                                    ? "minimal"
                                    : "default"
                                }
                              />
                            </SelectMenu>
                          </Pane>
                        )}
                        {di.label}{" "}
                        {sortedDimensionOrder === SORT_ORDER.ASC && (
                          <Icon
                            icon="caret-up"
                            size={13}
                            color="muted"
                            marginBottom={-1}
                            flexShrink={0}
                            marginLeft={4}
                            alignSelf="center"
                          />
                        )}
                        {sortedDimensionOrder === SORT_ORDER.DESC && (
                          <Icon
                            icon="caret-down"
                            size={13}
                            color="muted"
                            marginBottom={-1}
                            flexShrink={0}
                            marginLeft={4}
                            alignSelf="center"
                          />
                        )}
                      </Pane>
                    </TableTextHeaderCell>
                  );
                })}
            </TableHead>

            <Pane
              display="flex"
              backgroundColor="hsla(210, 20%, 99%, 1)"
              borderBottom="1px solid hsla(210, 20%, 94%, 1)"
              alignItems="center"
              paddingX={29}
              paddingY={8}
            >
              <Pane
                fontSize={12}
                lineHeight="22px"
                textAlign="center"
                color="hsla(215, 12%, 44%, 1)"
                fontStyle="italic"
                display="flex"
              >
                {!!Object.keys(visibleSortDimensions).length && (
                  <Pane>
                    Sorting by{" "}
                    {Object.keys(visibleSortDimensions).map((s, i) => (
                      <Pane is="span" key={s}>
                        <Pane is="span" fontWeight={600}>
                          {dimensions.find(f => f.dimension === s).label}
                        </Pane>
                        {i < Object.keys(visibleSortDimensions).length - 1 &&
                          ", then "}
                      </Pane>
                    ))}
                    .&nbsp;
                  </Pane>
                )}
                <Pane>
                  Showing {activeAccounts.slice(0, 500).length} of{" "}
                  {allAccountsLength} companies.
                </Pane>
              </Pane>
              <Pane marginLeft="auto" display="flex" alignItems="center">
                <SelectMenu
                  isMultiSelect
                  title="Select properties"
                  position="bottom-right"
                  options={dimensions
                    .filter(di => !di.hidden)
                    .map(di => ({
                      label: di.label,
                      value: di.dimension
                    }))}
                  selected={filterDimensions.map(f => f.dimension)}
                  onSelect={({ value }) =>
                    setFilterDimensions([
                      ...filterDimensions,
                      { dimension: value, filteredAttributes: [] }
                    ])
                  }
                  onDeselect={({ value }) => {
                    setSortDimensions(
                      Object.keys(sortDimensions).reduce((sum, curr) => {
                        if (curr !== value) {
                          return {
                            ...sum,
                            [curr]: sortDimensions[curr]
                          };
                        }
                        return sum;
                      }, {})
                    );

                    setFilterDimensions(
                      filterDimensions.filter(di => di.dimension !== value)
                    );
                  }}
                >
                  <Button iconBefore="th">Columns</Button>
                </SelectMenu>
              </Pane>
            </Pane>
            <Table.Body>
              {[...activeAccounts]
                .sort((a, b) => {
                  if (!Object.keys(visibleSortDimensions).length) {
                    return 0;
                  }

                  let sortResult = 0;

                  for (
                    let i = 0;
                    i < Object.keys(visibleSortDimensions).length;
                    i++
                  ) {
                    const dimension = dimensions.find(
                      di =>
                        di.dimension === Object.keys(visibleSortDimensions)[i]
                    );
                    if (dimension) {
                      if (visibleSortDimensions[dimension.dimension]) {
                        const accountMetaA =
                          a.traits && dimension.useTrait !== false
                            ? a.traits
                            : a;
                        const accountMetaB =
                          b.traits && dimension.useTrait !== false
                            ? b.traits
                            : b;

                        if (dimension.type === "stat") {
                          let aStat = !a.stats
                            ? 0
                            : a.stats
                                .filter(stat =>
                                  dimension.matches.some(match =>
                                    Object.keys(match).every(
                                      key => match[key] === stat[key]
                                    )
                                  )
                                )
                                .reduce(
                                  (sum, curr) =>
                                    curr[dimension.aggregate] + sum,
                                  0
                                );
                          let bStat = !b.stats
                            ? 0
                            : b.stats
                                .filter(stat =>
                                  dimension.matches.some(match =>
                                    Object.keys(match).every(
                                      key => match[key] === stat[key]
                                    )
                                  )
                                )
                                .reduce(
                                  (sum, curr) =>
                                    curr[dimension.aggregate] + sum,
                                  0
                                );

                          if (dimension.compare) {
                            let comparisonAStat = !a.stats
                              ? 0
                              : a.stats
                                  .filter(stat =>
                                    dimension.matches.some(match =>
                                      Object.keys(match).every(
                                        key => match[key] === stat[key]
                                      )
                                    )
                                  )
                                  .reduce(
                                    (sum, curr) =>
                                      curr[dimension.compare] + sum,
                                    0
                                  );
                            if (!comparisonAStat) {
                              aStat = 0;
                            } else {
                              aStat =
                                Math.round((100 * aStat) / comparisonAStat) /
                                100;
                            }

                            let comparisonBStat = !b.stats
                              ? 0
                              : b.stats
                                  .filter(stat =>
                                    dimension.matches.some(match =>
                                      Object.keys(match).every(
                                        key => match[key] === stat[key]
                                      )
                                    )
                                  )
                                  .reduce(
                                    (sum, curr) =>
                                      curr[dimension.compare] + sum,
                                    0
                                  );
                            if (!comparisonBStat) {
                              bStat = 0;
                            } else {
                              bStat =
                                Math.round((100 * bStat) / comparisonBStat) /
                                100;
                            }
                          }

                          sortResult = sortFunction(
                            aStat,
                            bStat,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.dimension === "users") {
                          sortResult = sortFunction(
                            a.users ? a.users.length : 0,
                            b.users ? b.users.length : 0,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.type === "_source") {
                          sortResult = sortFunction(
                            Object.keys(a.traits).length === 1 ? 1 : 0,
                            Object.keys(b.traits).length === 1 ? 1 : 0,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (
                          dimension.type === "_intercom_custom_origin"
                        ) {
                          sortResult = sortFunction(
                            a.traits.INTERCOM.CUSTOM_ORIGIN,
                            b.traits.INTERCOM.CUSTOM_ORIGIN,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (
                          dimension.type === "_intercom_custom_status"
                        ) {
                          sortResult = sortFunction(
                            a.traits.INTERCOM.CUSTOM_STATUS,
                            b.traits.INTERCOM.CUSTOM_STATUS,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.dimension === "assigned_csms") {
                          sortResult = sortFunction(
                            a.traits.OWNER_ID,
                            b.traits.OWNER_ID,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.type === "stats") {
                          let aDimensionValue, bDimensionValue;
                          if (dimension.selector) {
                            aDimensionValue = dimension.selector(
                              a.stats[dimension.dimension]
                            );
                            bDimensionValue = dimension.selector(
                              b.stats[dimension.dimension]
                            );
                          } else {
                            aDimensionValue = a.stats[dimension.dimension];
                            bDimensionValue = b.stats[dimension.dimension];
                          }

                          sortResult = sortFunction(
                            aDimensionValue === undefined
                              ? null
                              : aDimensionValue,
                            bDimensionValue === undefined
                              ? null
                              : bDimensionValue,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.dimension === "_revenue") {
                          sortResult = sortFunction(
                            a.stats
                              ? a.stats.MOST_RECENT_REVENUE_THIS_QUARTER /
                                  a.stats.MOST_RECENT_REVENUE_FORECAST || null
                              : null,
                            b.stats
                              ? b.stats.MOST_RECENT_REVENUE_THIS_QUARTER /
                                  b.stats.MOST_RECENT_REVENUE_FORECAST || null
                              : null,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.type === "old_health") {
                          const mostRecentHealthScoreA =
                            a.health_scores && a.health_scores.length > 0
                              ? a.health_scores[0].health_score
                              : 0;
                          const mostRecentHealthScoreB =
                            b.health_scores && b.health_scores.length > 0
                              ? b.health_scores[0].health_score
                              : 0;

                          if (Object.keys(visibleSortDimensions).length > 1) {
                            sortResult = sortFunction(
                              getHealthBucket(mostRecentHealthScoreA).order,
                              getHealthBucket(mostRecentHealthScoreB).order,
                              visibleSortDimensions[dimension.dimension]
                            );
                          } else {
                            sortResult = sortFunction(
                              mostRecentHealthScoreA,
                              mostRecentHealthScoreB,
                              visibleSortDimensions[dimension.dimension]
                            );
                          }
                        } else if (dimension.type === "health") {
                          let mostRecentHealthScoreA =
                            a.health_scores && a.health_scores.length
                              ? a.health_scores[0][dimension.accessor]
                              : 0;
                          let mostRecentHealthScoreB =
                            b.health_scores && b.health_scores.length
                              ? b.health_scores[0][dimension.accessor]
                              : 0;

                          if (
                            ["_health_score", "_blended_health_score"].includes(
                              dimension.dimension
                            ) &&
                            (!a.stats || !a.stats.MOST_RECENT_REVENUE_FORECAST)
                          ) {
                            mostRecentHealthScoreA = null;
                          }

                          if (
                            ["_health_score", "_blended_health_score"].includes(
                              dimension.dimension
                            ) &&
                            (!b.stats || !b.stats.MOST_RECENT_REVENUE_FORECAST)
                          ) {
                            mostRecentHealthScoreB = null;
                          }

                          mostRecentHealthScoreA = isNaN(mostRecentHealthScoreA)
                            ? null
                            : mostRecentHealthScoreA;
                          mostRecentHealthScoreB = isNaN(mostRecentHealthScoreB)
                            ? null
                            : mostRecentHealthScoreB;

                          sortResult = sortFunction(
                            mostRecentHealthScoreA,
                            mostRecentHealthScoreB,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.type === "clearbitRevenue") {
                          sortResult = sortFunction(
                            clearbitRevenueSortValue(
                              a.traits.COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE
                            ),
                            clearbitRevenueSortValue(
                              b.traits.COMPANY_METRICS_ESTIMATED_ANNUAL_REVENUE
                            ),
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (
                          dimension.type === "metricChangeOverTimeRatio"
                        ) {
                          sortResult = sortFunction(
                            a.stats[dimension.dimension] || null,
                            b.stats[dimension.dimension] || null,
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else if (dimension.type === "COMPANY_COMPUTED_SIZE") {
                          sortResult = sortFunction(
                            companySizeSortValue(
                              a.traits.COMPANY_COMPUTED_SIZE
                            ),
                            companySizeSortValue(
                              b.traits.COMPANY_COMPUTED_SIZE
                            ),
                            visibleSortDimensions[dimension.dimension]
                          );
                        } else {
                          sortResult = sortFunction(
                            accountMetaA[dimension.dimension] === undefined
                              ? null
                              : accountMetaA[dimension.dimension],
                            accountMetaB[dimension.dimension] === undefined
                              ? null
                              : accountMetaB[dimension.dimension],
                            visibleSortDimensions[dimension.dimension]
                          );
                        }
                        if (sortResult !== 0) {
                          return sortResult;
                        }
                      }
                    }
                  }
                  return sortResult;
                })
                .slice(0, 500)
                .map((account, index) => (
                  <TableRow
                    key={index}
                    isSelectable
                    is={Link}
                    to={`/accounts/${
                      account.group_id ? account.group_id : account.account_id
                    }`}
                    paddingX={17}
                  >
                    {dimensions
                      .filter(
                        di =>
                          !di.hidden &&
                          filterDimensions.find(
                            f => f.dimension === di.dimension
                          )
                      )
                      .map((di, index) => (
                        <DimensionCell
                          key={index}
                          dimension={di}
                          account={account}
                          owners={owners}
                          index={index}
                          userDomain={userDomain}
                        />
                      ))}
                  </TableRow>
                ))}
            </Table.Body>
          </Table>
        </Card>
      </Main>
    </>
  );
}

const PriorityOverlayTrigger = ({
  userDomain,
  stats,
  score_health,
  healthMeta,
  children
}) => {
  const [hoverRef, isHovered] = useHover();
  const hasHealthScoreExplanation =
    userDomain === CustomerDomainByCustomerId[CustomerId.H1] ||
    userDomain === CustomerDomainByCustomerId[CustomerId.Instabug];

  return (
    <div ref={hoverRef}>
      {stats && isHovered && (
        <Pane position="absolute" zIndex={2}>
          {!hasHealthScoreExplanation && (
            <PriorityOverlay
              userDomain={userDomain}
              stats={stats}
              score_health={score_health}
              healthMeta={healthMeta}
            />
          )}
          {hasHealthScoreExplanation && (
            <PriorityOverlayBreakdown
              userDomain={userDomain}
              stats={stats}
              score_health={score_health}
              healthMeta={healthMeta}
            />
          )}
        </Pane>
      )}
      {children}
    </div>
  );
};

const PriorityOverlayBreakdown = ({ userDomain, score_health, healthMeta }) => {
  return (
    <Overlay>
      <Row justifyContent="space-between">
        <Column>
          <PriorityScore>
            {isNaN(Math.round(score_health)) ? "-" : Math.round(score_health)}
          </PriorityScore>
        </Column>
        <Column>
          {" "}
          <Pane fontSize={SMALL_FONT_SIZE} color={FADED_GRAY}>
            {dateFns.formatDistanceToNow(new Date(healthMeta.timestamp))} ago
          </Pane>
        </Column>
      </Row>
      <Pane>
        <Pane marginTop={4}>
          {[...healthMeta.explanation]
            .sort((a, b) => {
              if (a.valence === "good" && b.valence !== "good") {
                return 1;
              }
              if (a.valence !== "good" && b.valence === "good") {
                return -1;
              }
              return 0;
            })
            .map((explanation, i) => (
              <Row color="#475872" fontSize={13} alignItems="center" key={i}>
                <Pane
                  borderRadius={3}
                  width={8}
                  height={8}
                  marginRight={11}
                  backgroundColor={
                    explanation.valence === "good" ? "#00bda5" : "#f2545b"
                  }
                  opacity={0.7}
                />
                <span>
                  <HealthScoreExplanation
                    healthScoreExplanation={explanation}
                    featureMetadata={
                      FEATURE_META[userDomain][explanation.field]
                    }
                  />
                </span>
              </Row>
            ))}
        </Pane>
      </Pane>
    </Overlay>
  );
};

const PriorityOverlay = ({ userDomain, stats, score_health, healthMeta }) => {
  let negativeStrength = 0;
  let positiveStrength = 0;

  let importantStats = [];

  if (userDomain === "upflow.io") {
    importantStats = Object.keys(stats).reduce((sum, currKey) => {
      if (currKey === "MOST_RECENT_NPS") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "NPS",
              polarity: "negative",
              value: stats[currKey]
            }
          ];
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "NPS",
              polarity: "positive",
              value: stats[currKey]
            }
          ];
        }
      } else if (currKey === "HAS_INTEGRATION_DATA_SOURCE") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "No data integration",
              polarity: "negative"
            }
          ];
        } else {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Has data integration",
              polarity: "positive"
            }
          ];
        }
      } else if (currKey === "HAS_C_LEVEL_ACTIVITY") {
        if (!stats[currKey]) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "No C-level activity",
              polarity: "negative"
            }
          ];
        } else {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Has C-level activity",
              polarity: "positive"
            }
          ];
        }
      } else if (currKey === "DAYS_SINCE_LAST_KEY_EVENT") {
        if (
          stats[currKey] === null ||
          stats[currKey] === undefined ||
          stats[currKey] > 7
        ) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "No key event last week",
              polarity: "negative"
            }
          ];
        } else {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Days since last key event",
              polarity: "positive",
              value: stats[currKey],
              type: "static"
            }
          ];
        }
      } else if (currKey === "COUNT_ACTIVE_USERS_LAST_WEEK") {
        if (stats[currKey] >= 1) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Active users last week",
              polarity: "positive",
              value: stats[currKey],
              type: "static"
            }
          ];
        } else {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "No active users last week",
              polarity: "negative"
            }
          ];
        }
      }
      return sum;
    }, []);
  } else if (userDomain === "shipbob.com") {
    importantStats = Object.keys(stats).reduce((sum, currKey) => {
      if (currKey === "AVERAGE_MC_SAT") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "Average MC Satisfaction",
              polarity: "negative",
              value: stats[currKey]
            }
          ];
        } else if (stats[currKey] > 4.8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Average MC Satisfaction",
              polarity: "positive",
              value: stats[currKey]
            }
          ];
        }
      } else if (currKey === "AVERAGE_SB_SAT") {
        if (stats[currKey] < 4.6) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "Average SB Satisfaction",
              polarity: "negative",
              value: stats[currKey],
              type: "static"
            }
          ];
        } else if (stats[currKey] > 4.8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Average SB Satisfaction",
              polarity: "positive",
              value: stats[currKey],
              type: "static"
            }
          ];
        }
      } else if (currKey === "MOST_RECENT_SB_SAT") {
        if (stats[currKey] - stats["AVERAGE_SB_SAT"] < 0) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "SB Satisfaction recently",
              polarity: "negative",
              value: stats[currKey]
            }
          ];
        } else if (stats[currKey] - stats["AVERAGE_SB_SAT"] > 0) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "SB Satisfaction recently",
              polarity: "positive",
              value: stats[currKey]
            }
          ];
        }
      } else if (currKey === "AVERAGE_NPS") {
        if (stats[currKey] < 7) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "Average NPS",
              polarity: "negative",
              value: Math.round(stats[currKey] * 10) / 10,
              type: "static"
            }
          ];
        } else if (stats[currKey] >= 8) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Average NPS",
              polarity: "positive",
              value: Math.round(stats[currKey] * 10) / 10,
              type: "static"
            }
          ];
        }
      } else if (currKey === "MOST_RECENT_NPS") {
        if (stats[currKey] - stats["AVERAGE_NPS"] < 0) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "NPS recently",
              polarity: "negative",
              value: stats[currKey]
            }
          ];
        } else if (stats[currKey] - stats["AVERAGE_NPS"] > 0) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "NPS recently",
              polarity: "positive",
              value: stats[currKey]
            }
          ];
        }
      } else if (currKey === "MOST_RECENT_AVERAGE_COST_PER_ORDER_CHANGE") {
        if (stats[currKey] > 20) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "Average cost per order change",
              polarity: "negative",
              language: " recently increased by ",
              value: stats[currKey],
              suffix: "%"
            }
          ];
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Average cost per order change",
              polarity: "positive",
              language: " recently decreased by ",
              value: stats[currKey],
              suffix: "%"
            }
          ];
        }
      } else if (currKey === "MOST_RECENT_CASE_ORDER_RATIO_CHANGE") {
        if (stats[currKey] >= 20) {
          negativeStrength = negativeStrength + 1;
          return [
            ...sum,
            {
              label: "Case to order ratio change",
              polarity: "negative",
              language: " recently increased by ",
              value: stats[currKey],
              suffix: "%"
            }
          ];
        } else if (stats[currKey] < -20) {
          positiveStrength = positiveStrength + 1;
          return [
            ...sum,
            {
              label: "Case to order ratio change",
              polarity: "positive",
              language: " recently decreased by ",
              value: stats[currKey],
              suffix: "%"
            }
          ];
        }
      }

      return sum;
    }, []);
  }

  const sortedNegatives = importantStats.filter(
    stat => stat.polarity === "negative"
  );
  const sortedPositives = importantStats.filter(
    stat => stat.polarity === "positive"
  );

  return (
    <Overlay>
      <Row justifyContent="space-between">
        <Column>
          <PriorityScore>
            {isNaN(Math.round(score_health)) ? "-" : Math.round(score_health)}
          </PriorityScore>
        </Column>
        <Column color={FADED_GRAY} fontSize={SMALL_FONT_SIZE}>
          {dateFns.formatDistanceToNow(
            new Date(healthMeta.timestamp || healthMeta.UPDATED_AT)
          )}{" "}
          ago
        </Column>
      </Row>
      <Pane>
        <Pane marginTop={4}>
          {[...sortedNegatives, ...sortedPositives].map((obj, i) => (
            <Row color="#475872" fontSize={13} alignItems="center" key={i}>
              <Pane
                borderRadius={3}
                width={8}
                height={8}
                marginRight={11}
                backgroundColor={
                  obj.polarity === "positive" ? "#00bda5" : "#f2545b"
                }
                opacity={0.7}
              />
              <span>
                <Pane is="span" fontWeight={600}>
                  {obj.label}
                </Pane>
                {obj.value !== undefined && obj.type === "static" && " is "}
                {obj.value !== undefined &&
                  obj.polarity === "positive" &&
                  obj.type !== "static" &&
                  (obj.language || " went up to ")}
                {obj.value !== undefined &&
                  obj.polarity === "negative" &&
                  obj.type !== "static" &&
                  (obj.language || " went down to ")}
                {obj.value ? obj.prefix : ""}
                {obj.value}
                {obj.value ? obj.suffix : ""}
              </span>
            </Row>
          ))}
        </Pane>
      </Pane>
    </Overlay>
  );
};

const Overlay = styled(Card)`
  position: relative;
  padding: 18px 16px;
  margin-left: -17px;
  margin-top: -19px;
  border: 1px solid #d6dbe7;
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.17),
    0px 4px 100px rgba(0, 0, 0, 0.05);
`;

const PriorityScore = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #374151;
`;

const TableTextCell = props => (
  <Table.TextCell
    flex={props.flex || 1}
    title={props.title}
    minWidth={0}
    overflow="visible"
  >
    <StyledTableTextCell>{props.children}</StyledTableTextCell>
  </Table.TextCell>
);
const StyledTableTextCell = styled.div`
  font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: normal;

  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #4b5563;
`;

const TableTextHeaderCell = props => (
  <Table.TextHeaderCell flex={props.flex || 1}>
    <Header>
      <TableTextHeaderCellInner
        display="flex"
        justifyContent={props.justifyContent || "center"}
        textAlign="center"
      >
        {props.children}
      </TableTextHeaderCellInner>
    </Header>
  </Table.TextHeaderCell>
);

const TableTextHeaderCellInner = styled(Pane)`
  position: relative;

  .hover-hidden {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: -28px;
    left: 0;

    &.visible button {
      opacity: 0.7;
      pointer-events: all;
    }

    button {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    .hover-hidden button {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const TableHead = props => (
  <Table.Head
    borderTopLeftRadius={6}
    borderTopRightRadius={6}
    backgroundColor="#f9fafb"
    background="#f9fafb!important"
    paddingY={20}
    height="auto"
    fontFamily="Inter var,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji"
    fontWeight={500}
    fontSize="12px"
    lineHeight="16px"
    letterSpacing="0.05em"
    color="#6B7280"
    {...props}
  />
);

const CompanyLogo = styled.img`
  border-radius: 6px;
  width: 24px;
  max-height: 24px;
  margin-right: 21px;
`;

export default Accounts;
