import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      const options = {};

      if (window.localStorage.getItem("_q_existing") !== "true") {
        options = {
          access_type: "offline",
          connection_scope: [
            "https://www.googleapis.com/auth/gmail.readonly",
            "https://www.googleapis.com/auth/calendar.events.readonly",
            "https://www.googleapis.com/auth/calendar.readonly"
          ],
          approval_prompt: "force",
          appState: { targetUrl: path }
        };
      }

      await loginWithRedirect(options);
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true ? (
      <Component user={user} {...rest} {...props} />
    ) : null;

  return <Route path={path} render={render} />;
};

export default PrivateRoute;
