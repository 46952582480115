import { CustomerDomainByCustomerId, CustomerId } from "./customerConfig";
import { toPercent } from "../helpers/ratio";
import { toPercentWithCommas } from "../helpers/percent";

export const FeatureType = {
  Integer: "integer",
  Percent: "percent",
  Boolean: "boolean"
};

export const FeatureComparison = {
  Equality: "equality",
  Trend: "trend",
  None: "none"
};

export const FEATURE_META = {
  "h1insights.com": {
    pct_of_users_in_last_30: {
      field: "pct_of_users_in_last_30",
      label: "% of users active in last 30 days",
      type: FeatureType.Percent,
      comparison: FeatureComparison.Equality,
      transformValue: fraction => `${Math.round(100 * fraction)}%`,
      defaultValue: 0
    },
    has_user_in_last_15: {
      field: "has_user_in_last_15",
      label: "active in last 15 days",
      type: FeatureType.Boolean,
      comparison: FeatureComparison.None,
      transformValue: bool => bool,
      defaultValue: 0
    }
  },
  [CustomerDomainByCustomerId[CustomerId.Instabug]]: {
    num_bug_events_week_ratio: {
      field: "num_bug_events_week_ratio",
      label: "bug report active usage WoW",
      type: FeatureType.Percent,
      comparison: FeatureComparison.Trend,
      transformValue: ratio =>
        `${toPercentWithCommas(Math.abs(toPercent(ratio)))}%`
    },
    num_crash_report_events_week_ratio: {
      field: "num_crash_report_events_week_ratio",
      label: "crash report active usage WoW",
      type: FeatureType.Percent,
      comparison: FeatureComparison.Trend,
      transformValue: ratio =>
        `${toPercentWithCommas(Math.abs(toPercent(ratio)))}%`
    },
    num_survey_events_week_ratio: {
      field: "num_survey_events_week_ratio",
      label: "in-app survey active usage WoW",
      type: FeatureType.Percent,
      comparison: FeatureComparison.Trend,
      transformValue: ratio =>
        `${toPercentWithCommas(Math.abs(toPercent(ratio)))}%`
    }
  }
};
