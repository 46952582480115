export const API_HOST =
  process.env.NODE_ENV !== "production"
    ? "//localhost:5000"
    : "//api2.quantif.ai";

const RITEKIT_CLIENT_ID = "161f21e7cd575cdb711cce72c444353bf7910c2ba232";
export const RITEKIT_URL = `https://api.ritekit.com/v1/images/logo?client_id=${RITEKIT_CLIENT_ID}&domain=`;

export const CSMS = [
  { email: "jason@appcues.com", firstName: "Jason", lastName: "Phung" },
  { email: "emma@appcues.com", firstName: "Emma", lastName: "Crimmins" },
  { email: "okello@appcues.com", firstName: "Okello", lastName: "Carter" },
  { email: "eric.dixon@appcues.com", firstName: "Eric", lastName: "Dixon" },
  { email: "jackie@appcues.com", firstName: "Jackie", lastName: "Smith" },
  { email: "nicole@appcues.com", firstName: "Nicole", lastName: "N" },
  { email: "lily@appcues.com", firstName: "Lily", lastName: "Rosenbloom" },
  { email: "shonak@appcues.com", firstName: "Shonak", lastName: "Patel" }
];

export const COLOR_RANGE = [
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B",
  "#BDA9EA",
  "#81C1FD",
  "#EA90B1",
  "#F5C26B"
];

export const LIFECYCLE_STAGE = {
  ONBOARDING: "Onboarding",
  ADOPTION: "Adoption",
  EXPANSION: "Expansion",
  STEADY_STATE: "Steadystate",
  PENDING: "Pending",
  RENEWAL: "Renewal"
};

export const LIFECYCLE_STAGES = {
  [LIFECYCLE_STAGE.ONBOARDING]: {
    color: "#4B81BB",
    backgroundColor: "#D5E9F3"
  },
  [LIFECYCLE_STAGE.ADOPTION]: {
    color: "#7474E7",
    backgroundColor: "#EAEAF8"
  },
  [LIFECYCLE_STAGE.EXPANSION]: {
    color: "#4099A2",
    backgroundColor: "#D9EDE5"
  },
  [LIFECYCLE_STAGE.STEADY_STATE]: {
    color: "#4099A2",
    backgroundColor: "#D9EDE5",
    label: "Steady state"
  },
  [LIFECYCLE_STAGE.PENDING]: {
    color: "#7474E7",
    backgroundColor: "#EAEAF8"
  },
  [LIFECYCLE_STAGE.RENEWAL]: {
    color: "#7474E7",
    backgroundColor: "#EAEAF8"
  }
};

export const PRIORITY_BREAKDOWNS = [
  {
    companyName: "Notion",
    priority: 94,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 2,
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 3,
        action: "Find related users"
      },
      {
        label: "Created only one style",
        polarity: "negative",
        strength: 5,
        action: "Send “Designer Case Study” email"
      }
    ]
  },
  {
    companyName: "Github",
    priority: 91,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 1,
        absolute: "31%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 4,
        absolute: 2,
        action: "Find related users"
      },
      {
        label: "Created only one style",
        polarity: "negative",
        strength: 3,
        action: "Send “Designer Case Study” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 7,
        strength: 1
      }
    ]
  },
  {
    companyName: "Dropbox",
    priority: 88,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 3,
        absolute: "45%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 4,
        absolute: 2,
        action: "Find related users"
      },
      {
        label: "Created only one style",
        polarity: "negative",
        strength: 2,
        action: "Send “Designer Case Study” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 8,
        strength: 2
      }
    ]
  },
  {
    companyName: "Toast",
    priority: 87,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 5,
        absolute: "41%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 2,
        absolute: 3,
        action: "Find related users"
      },
      {
        label: "Created only one style",
        polarity: "negative",
        strength: 1.5,
        action: "Send “Designer Case Study” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        strength: 1,
        absolute: 11
      }
    ]
  },
  {
    companyName: "Microsoft",
    priority: 84,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 3,
        action: "Send “Flow ROI” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        strength: 1
      }
    ]
  },
  {
    companyName: "Atlassian",
    priority: 76,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 3,
        absolute: "39%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        absolute: 1,
        strength: 4
      },
      {
        label: "Created only one style",
        polarity: "positive",
        strength: 2,
        action: "Send “Designer Case Study” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 7,
        strength: 2
      }
    ]
  },
  {
    companyName: "Shopify",
    priority: 76,
    priorityBreakdowns: [
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 3,
        absolute: 2,
        action: "Find related users"
      },
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 4,
        absolute: "42%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Created only one style",
        polarity: "positive",
        strength: 2,
        action: "Send “Designer Case Study” email"
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 7,
        strength: 2
      }
    ]
  },
  {
    companyName: "Slack",
    priority: 61,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 5,
        absolute: "48%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Created more than four styles",
        polarity: "positive",
        absolute: 5,
        strength: 2
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 7,
        strength: 2
      }
    ]
  },
  {
    companyName: "Squarespace",
    priority: 52,
    priorityBreakdowns: [
      {
        label: "Weekly flows shown declined >30%",
        polarity: "negative",
        strength: 3.5,
        absolute: "39%",
        action: "Send “Flow ROI” email"
      },
      {
        label: "Less than 4 users on account",
        polarity: "negative",
        strength: 1,
        absolute: 3,
        action: "Find related users"
      },
      {
        label: "Created more than four styles",
        polarity: "positive",
        absolute: 5,
        strength: 2
      },
      {
        label: "Published more than 6 flows",
        polarity: "positive",
        absolute: 7,
        strength: 2
      }
    ]
  }
];

export const COMPANY_DATA = [
  {
    iconSrc: "https://logo.clearbit.com/notion.so",
    name: "Notion",
    domain: "www.notion.so",
    priority: 94,
    lastOnline: "3 days ago",
    lifecycleStage: "Onboarding",
    mrr: 399,
    customerFor: "18 days",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ONBOARDING
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 399,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Notion databases can be simple or complex - whatever you need! 🙌 1) Start an easy daily log *OR* 2) Run a full CRM. New templates here: https://www.notion.so/notion/Notion-Template-Gallery"
          },
          linkedin: {
            text:
              "New jobs posted! Looking for the following humans: Customer Success 👩‍🚀 Sales & Growth 🐶 Community & Support Engineers 🦊 Full Stack 🐙 Backend 👩‍🔬 Growth 🐝 Mobile Marketing 🦁 Video Producer 🐱 Content Marketer"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/github.com",
    name: "Github",
    domain: "www.github.com",
    priority: 91,
    lastOnline: "18 hours ago",
    lifecycleStage: "Adoption",
    mrr: 499,
    customerFor: "21 days",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ADOPTION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 499,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Want to open a new repository or gist in seconds? ➡ http://github.new , http://repo.new , http://gist.new. And go!"
          },
          linkedin: {
            text:
              "Do you like meeting with fellow developers? Hearing the latest on GitHub products? Customizable octocat stickers? Experience it all (and more) at GitHub Universe, November 13-14 in SF."
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/dropbox.com",
    name: "Dropbox",
    domain: "www.dropbox.com",
    priority: 88,
    lastOnline: "3 days ago",
    lifecycleStage: "Onboarding",
    mrr: 499,
    customerFor: "7 days",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ONBOARDING
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 499,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Filmmaker @AlexPrager brings us on her journey of collaborating with artists and producers to develop her newest film, Play the Wind. http://bit.ly/2PfeugD"
          },
          linkedin: {
            text:
              "Dropbox Spaces transforms your folders into connected workspaces that bring your team’s content and tools together—from PowerPoints to Google Docs, Trello boards, and more. #DropboxWIP http://bit.ly/2mEzjGa"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/toasttab.com",
    name: "Toast",
    domain: "www.toast.com",
    priority: 87,
    lastOnline: "3 days ago",
    lifecycleStage: "Expansion",
    mrr: 999,
    customerFor: "11 months",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.EXPANSION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 1299,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "We get it. Running a restaurant is hard. Which is why we put together the Restaurant Success in 2019 Industry Report, where restaurateurs and guests alike were surveyed on what makes a great restaurant."
          },
          linkedin: {
            text:
              "Toast acquires StratEx HR to simplify restaurant payroll & team management and empower restaurants running on Toast to attract, hire, and retain great teams."
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/microsoft.com",
    name: "Microsoft",
    domain: "www.microsoft.com",
    priority: 84,
    lastOnline: "4 days ago",
    lifecycleStage: "Adoption",
    mrr: 99,
    customerFor: "14 months",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ADOPTION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 99,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "We're helping developers create and employ autonomous intelligent systems with IoT and #AI technology. Here's how: http://msft.social/SdIZDs 🤖"
          },
          linkedin: {
            text:
              "The Miami Heat is using #PowerBI's data visualization as the \"key\" to innovation. Here's how: http://msft.social/66LNlT"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/atlassian.com",
    name: "Atlassian",
    domain: "www.atlassian.com",
    priority: 76,
    lastOnline: "Online",
    lifecycleStage: "Onboarding",
    mrr: 99,
    customerFor: "13 days",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ONBOARING
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 99,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text: "Be a change maker on your team. #AtlassianOpen"
          },
          linkedin: {
            text:
              "When's the last time you took a *real* sick day? 🤧What keeps you from completely switching off? #WorkLifeBalance #SickDay"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/shopify.com",
    name: "Shopify",
    domain: "www.shopify.com",
    priority: 76,
    lastOnline: "9 days ago",
    lifecycleStage: "Adoption",
    mrr: 99,
    customerFor: "29 months",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.ADOPTION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 99,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Today we’re celebrating the 1 million businesses that are built on Shopify. The ones who are rewriting the rules of our modern economy and pursuing independence. #Shopify1Million"
          },
          linkedin: {
            text:
              "Oh hey, Toronto you're looking fresh and fine today - is that a new haircut? Here's to our new space in Toronto, where we're set to double our Shopifolk headcount by 2022 all to build better tools and services for business owners here, there, and everywhere. #ShopifyTO"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/slack.com",
    name: "Slack",
    domain: "www.slack.com",
    priority: 61,
    lastOnline: "5 hours ago",
    lifecycleStage: "Expansion",
    mrr: 399,
    customerFor: "4 months",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.EXPANSION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 399,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Decide what you want to hear, when you want to hear it. When it's time to focus, you can now mute channels *and* conversations. #changelog"
          },
          linkedin: {
            text:
              "When you care for 2,400 cows, you can't let your communication be spotty. Wickstrom Dairies uses Slack to keep its equipment functioning, cows fed and workers aligned. http://bit.ly/36iqXWU"
          }
        }
      }
    ]
  },
  {
    iconSrc: "https://logo.clearbit.com/squarespace.com",
    name: "Squarespace",
    domain: "www.squarespace.com",
    priority: 52,
    lastOnline: "Online",
    lifecycleStage: "Expansion",
    mrr: 399,
    customerFor: "13 days",
    meta: [
      {
        name: "iconSrc",
        value: "https://logo.clearbit.com/toasttab.com",
        hidden: true
      },
      {
        name: "Stage",
        value: LIFECYCLE_STAGE.EXPANSION
      },
      {
        name: "Products",
        value: ["Basic", "Volume Add-on"]
      },
      {
        name: "MRR",
        value: 399,
        unit: "$"
      },
      {
        name: "Last Seen",
        value: "3 days ago"
      },
      {
        name: "Renewal Date",
        value: "2019/09/28"
      },
      {
        name: "Customer Since",
        value: "2018/09/28"
      },
      {
        name: "Owner",
        value: "jackieSmith"
      },
      {
        name: "Current Champion",
        value: "mikeLemire"
      },
      {
        name: "social",
        hidden: true,
        value: {
          twitter: {
            text:
              "Tonight's the night: the @nyknicks return to the @TheGarden for their season home opener. Let's get it done. #NewYorkForever #ProudSponsor"
          },
          linkedin: {
            text:
              '"I want to serve people the food I grew up with – that was my dream." In the summer of 2018, at age 59, chef Nasim Alikhani realized a lifelong goal – opening her first-ever restaurant, Sofreh, to critical acclaim. As #WomensSmallBusinessMonth comes to a close, we spoke with Nasim – a #Squarespace customer – about her background, her passion for Persian cooking, as well as what it meant to see Sofreh come to fruition. #MakeIt '
          }
        }
      }
    ]
  }
];

export { default as FREEMAIL_DOMAINS } from "./freemailDomains";
export * from "./customerConfig";
