import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Pane, Spinner } from "evergreen-ui";
import Helmet from "react-helmet";

import { Row, Panel, Main, H1 } from "../../components/base";
import { useFetchAPI } from "../../helpers/data";
import {
  CustomerDomainByCustomerId,
  CustomerId,
  domainToCustomerId
} from "../../constants";
import AlertSideSheet from "./AlertSideSheet";
import ExampleAlert from "./ExampleAlert";
import { EVENT_TYPES, ANY_PAGE, STATUS_COLORS } from "./constants";

const PanelCard = styled(Pane)`
  width: 300px;
  border-radius: 6px;
  background-color: #f4f5f7;
  font-size: 14px;
  padding: 20px 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s ease;

  position: relative;
  overflow: hidden;

  ${({ isDisabled }) =>
    isDisabled
      ? `
    pointer-events: none;
    opacity: 0.4;
  `
      : ""}

  ${({ selectedColor }) =>
    selectedColor
      ? `
    box-shadow: inset 0 0 0 2px ${selectedColor};
  `
      : ""}

  &:hover {
    background-color: hsla(220, 16%, 93%, 1);
  }

  h2 {
    font-size: 1rem;
    margin: 0;

    font-weight: 600;

    & + div {
      padding-top: 10px;
      line-height: 20px;
      opacity: 0.9;
    }
  }
`;

const StyledMain = styled(Main)`
  color: #374151;
`;

const sortNumericallyOnObjectKey = key => (a, b) => b[key] - a[key];

const isPageEvent = (event, userDomain) => {
  if (userDomain === CustomerDomainByCustomerId[CustomerId.Upflow]) {
    return event.event.toLowerCase().includes("render");
  } else if (userDomain === CustomerDomainByCustomerId[CustomerId.H1]) {
    return event.type === "TRACK";
  }
  return true;
};
const isTrackEvent = (event, userDomain) => {
  if (userDomain === CustomerDomainByCustomerId[CustomerId.Upflow]) {
    return !event.event.toLowerCase().includes("render");
  } else if (userDomain === CustomerDomainByCustomerId[CustomerId.H1]) {
    return event.type === "PAGE";
  }
  return true;
};

function Alerts({ userDomain, user, Nav }) {
  const [isSideSheetVisible, setIsSideSheetVisible] = useState(false);
  const [audience, setAudience] = useState("all");
  const [trigger, setTrigger] = useState("any");
  const [triggerConditionType, setTriggerConditionType] = useState(
    EVENT_TYPES.PAGE
  );
  const [triggerConditionPage, setTriggerConditionPage] = useState(ANY_PAGE);
  const [triggerConditionEvent, setTriggerConditionEvent] = useState(null);
  const [
    triggerConditionWaitDuration,
    setTriggerConditionWaitDuration
  ] = useState(1);
  const [
    triggerConditionWaitDurationUnit,
    setTriggerConditionWaitDurationUnit
  ] = useState("weeks");
  const [frequency, setFrequency] = useState("immediately");
  const [frequencyTime, setFrequencyTime] = useState(9);
  const [frequencyTimeAMPM, setFrequencyTimeAMPM] = useState("AM");
  const [frequencyDay, setFrequencyDay] = useState("Mon");
  const [location, setLocation] = useState("slack");
  const [alertName, setAlertName] = useState("");
  const [alertId, setAlertId] = useState();
  const [status, setStatus] = useState();
  const [description, setDescription] = useState("");

  const [sortedEvents, setSortedEvents] = useState([]);
  const [sortedPages, setSortedPages] = useState([]);
  const events = useFetchAPI("events", `/events`, [], {
    identifier: userDomain,
    fetchOnly: true
  });
  const [alerts, setAlerts] = useState(null);
  const fetchedAlerts = useFetchAPI("alerts", `/alerts`, null, {
    identifier: userDomain,
    fetchOnly: true
  });

  const recentUsers = useFetchAPI("recentUsers", `/users/recent?limit=30`, [], {
    identifier: userDomain,
    fetchOnly: true
  });
  const [recentAccounts, setRecentAccounts] = useState({});
  useEffect(() => {
    if (recentUsers.length) {
      setRecentAccounts(
        recentUsers.reduce(
          (sum, curr) => ({
            ...sum,
            [curr.group_name]: [...(sum[curr.group_name] || []), curr]
          }),
          {}
        )
      );
    }
  }, [recentUsers]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Alerts");
      window.analytics.track("Page loaded", {
        name: "Alerts",
        url: window.location.pathname,
        email: user.email,
        customerId: domainToCustomerId[userDomain]
      });
    }
  }, []);

  useEffect(() => {
    setAlerts(fetchedAlerts);
  }, [fetchedAlerts]);

  useEffect(() => {
    setSortedEvents(
      events
        .filter(e => isTrackEvent(e, userDomain))
        .sort(sortNumericallyOnObjectKey("frequency"))
    );
    setSortedPages([
      { event: ANY_PAGE },
      ...events
        .filter(e => isPageEvent(e, userDomain))
        .sort(sortNumericallyOnObjectKey("frequency"))
    ]);
  }, [events]);

  const openAlertSideSheet = (
    alertId,
    name,
    description,
    {
      audience,
      trigger,
      triggerConditionType,
      triggerConditionEvent,
      triggerConditionPage,
      triggerConditionWaitDuration,
      triggerConditionWaitDurationUnit,
      frequency,
      frequencyTime,
      frequencyTimeAMPM,
      frequencyDay,
      location
    },
    status
  ) => {
    setAlertId(alertId);
    setAlertName(name);
    setDescription(description);
    setStatus(status);

    if (trigger !== undefined) {
      setTrigger(trigger);
    }
    if (triggerConditionType !== undefined) {
      setTriggerConditionType(triggerConditionType);
    }
    if (triggerConditionEvent !== undefined) {
      setTriggerConditionEvent(triggerConditionEvent);
    }
    if (triggerConditionPage !== undefined) {
      setTriggerConditionPage(triggerConditionPage);
    }
    if (triggerConditionWaitDuration !== undefined) {
      setTriggerConditionWaitDuration(triggerConditionWaitDuration);
    }
    if (triggerConditionWaitDurationUnit !== undefined) {
      setTriggerConditionWaitDurationUnit(triggerConditionWaitDurationUnit);
    }
    if (frequency !== undefined) {
      setFrequency(frequency);
    }
    if (frequencyTime !== undefined) {
      setFrequencyTime(frequencyTime);
    }
    if (frequencyTimeAMPM !== undefined) {
      setFrequencyTimeAMPM(frequencyTimeAMPM);
    }
    if (frequencyDay !== undefined) {
      setFrequencyDay(frequencyDay);
    }

    setIsSideSheetVisible(true);

    if (window.analytics) {
      window.analytics.track("Alert opened", {
        name,
        alertId
      });
    }
  };

  const PRESET_ALERTS = [
    {
      name: "New user alert",
      description: "Tell me immediately every time a brand new user logs in",
      conditions: {
        audience: "custom",
        trigger: "any",
        frequency: "immediately",
        location: "slack"
      }
    },
    {
      name: "Daily usage alert",
      description: "At the end of each day, tell me who logged in that day",
      conditions: {
        audience: "all",
        trigger: "any",
        frequency: "daily",
        frequencyTime: 7,
        frequencyTimeAMPM: "PM",
        location: "slack"
      }
    },
    {
      name: "Trial activation alert",
      description:
        "Tell me immediately when my prospects hit activation in their trials",
      conditions: {
        audience: "all",
        trigger: "specific",
        triggerConditionType: "event",
        frequency: "immediately",
        location: "slack"
      }
    },
    {
      name: "Weekly usage dropoff alert",
      description:
        "Every Monday, tell me which customers have not performed any key actions in last week",
      conditions: {
        audience: "all",
        trigger: "lack",
        frequency: "weekly",
        frequencyDay: "Mon",
        frequencyTime: 9,
        frequencyTimeAMPM: "AM",
        location: "slack"
      }
    },
    {
      name: "Overage alert",
      disabled: true,
      description:
        "Every night, tell me which customers are newly over their plan's usage limits",
      conditions: {
        audience: "all",
        trigger: "any",
        frequency: "daily",
        frequencyTime: 11,
        frequencyTimeAMPM: "PM",
        location: "slack"
      }
    },
    {
      name: "All activity alert",
      description: "Tell me immediately when my users do any activity",
      conditions: {
        audience: "all",
        trigger: "any",
        frequency: "immediately",
        location: "slack"
      }
    }
  ];

  const loading =
    !sortedEvents.length ||
    !Object.keys(recentAccounts).length ||
    alerts === null;

  return (
    <>
      <Nav pageTitle="Alerts" />
      <Helmet defer={false} title={`Alerts | Quantifai`}>
        <link rel="shortcut icon" href="/favicon-96x96.png" />
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StyledMain>
        <AlertSideSheet
          {...{
            isSideSheetVisible,
            setIsSideSheetVisible,
            setAlerts,
            audience,
            setAudience,
            trigger,
            setTrigger,
            triggerConditionType,
            setTriggerConditionType,
            triggerConditionEvent,
            setTriggerConditionEvent,
            triggerConditionPage,
            setTriggerConditionPage,
            triggerConditionWaitDuration,
            setTriggerConditionWaitDuration,
            triggerConditionWaitDurationUnit,
            setTriggerConditionWaitDurationUnit,
            frequency,
            setFrequency,
            frequencyTime,
            setFrequencyTime,
            frequencyTimeAMPM,
            setFrequencyTimeAMPM,
            frequencyDay,
            setFrequencyDay,
            location,
            setLocation,
            sortedEvents,
            sortedPages,
            description,
            alertName,
            alertId,
            status
          }}
        />

        <Panel paddingX={36} minHeight={400}>
          {!loading && alerts && !!alerts.length && (
            <>
              <H1 paddingBottom={20}>My Alerts</H1>

              <Row flexWrap="wrap" marginRight={-30} marginBottom={20}>
                {alerts.map(alert => {
                  return (
                    <PanelCard
                      key={alert.id}
                      selectedColor={
                        STATUS_COLORS[alert.status].backgroundColor
                      }
                      onClick={() =>
                        openAlertSideSheet(
                          alert.id,
                          alert.name,
                          alert.description,
                          alert.conditions,
                          alert.status
                        )
                      }
                    >
                      <Row>
                        <h2>{alert.name}</h2>
                        <PanelCardStatusTag status={alert.status} />
                      </Row>
                    </PanelCard>
                  );
                })}
              </Row>
            </>
          )}

          <H1 paddingBottom={20}>Preset Alerts</H1>
          {loading && (
            <Row justifyContent="center" flexGrow={1} alignItems="center">
              <Spinner />
            </Row>
          )}
          {!loading && (
            <>
              <Row flexWrap="wrap" marginRight={-30}>
                {PRESET_ALERTS.map(presetAlert => {
                  return (
                    <PanelCard
                      key={presetAlert.name}
                      isDisabled={presetAlert.disabled}
                      onClick={() =>
                        openAlertSideSheet(
                          presetAlert.id,
                          presetAlert.name,
                          presetAlert.description,
                          presetAlert.conditions
                        )
                      }
                    >
                      <h2>{presetAlert.name}</h2>
                      <Pane>{presetAlert.description}</Pane>
                    </PanelCard>
                  );
                })}
              </Row>
            </>
          )}
        </Panel>
        {isSideSheetVisible && (
          <ExampleAlert
            {...{
              audience,
              trigger,
              triggerConditionType,
              triggerConditionEvent,
              triggerConditionPage,
              triggerConditionWaitDuration,
              triggerConditionWaitDurationUnit,
              frequency,
              frequencyTime,
              frequencyTimeAMPM,
              frequencyDay,
              location,
              userDomain,
              recentAccounts
            }}
          />
        )}
      </StyledMain>
    </>
  );
}

const PanelCardStatusTag = ({ status }) => {
  return (
    <StyledPanelCardStatusTag is="span" selectedColor={STATUS_COLORS[status]}>
      {status}
    </StyledPanelCardStatusTag>
  );
};

const StyledPanelCardStatusTag = styled(Pane)`
  padding: 4px 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: ${({ selectedColor }) => selectedColor.color};
  background-color: ${({ selectedColor }) => selectedColor.backgroundColor};
`;

export default Alerts;
