import React from "react";
import styled from "styled-components";
import { Pane } from "evergreen-ui";

export const Header = styled(Pane)`
  font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #6b7280;
  font-weight: 500;
`;

export const H1 = props => <StyledH1 is="h1" {...props} />;

const StyledH1 = styled(Pane)`
  color: #374151;
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 4px;
`;
