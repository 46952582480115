import React from "react";
import { Pane } from "evergreen-ui";
import styled from "styled-components";

export const Row = props => <Pane display="flex" {...props} />;
export const Column = props => <Row flexDirection="column" {...props} />;

export const Center = styled(Pane)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
