import React from "react";
import styled from "styled-components";
import {
  Pane,
  Select,
  TextInput,
  Button,
  SegmentedControl,
  Combobox,
  SideSheet,
  Radio,
  toaster
} from "evergreen-ui";

import { Row } from "../../components/base";
import { fetchWithAuth } from "../../helpers/data";
import { EVENT_TYPES, ALERT_STATUS } from "./constants";

const postAlert = async (
  shouldActivate,
  setAlerts,
  setIsSideSheetVisible,
  {
    alertName,
    alertId,
    description,
    audience,
    trigger,
    triggerConditionType,
    triggerConditionEvent,
    triggerConditionPage,
    triggerConditionWaitDuration,
    triggerConditionWaitDurationUnit,
    frequency,
    frequencyTime,
    frequencyTimeAMPM,
    frequencyDay,
    location,
    status
  }
) => {
  const body = {
    name: alertName,
    description,
    id: alertId,
    conditions: {
      audience,
      trigger,
      triggerConditionType,
      triggerConditionEvent,
      triggerConditionPage,
      triggerConditionWaitDuration,
      triggerConditionWaitDurationUnit,
      frequency,
      frequencyTime,
      frequencyTimeAMPM,
      frequencyDay,
      location
    },
    status: shouldActivate ? ALERT_STATUS.PENDING : ALERT_STATUS.INACTIVE
  };

  let toasterMessage = "";
  if (shouldActivate && status === ALERT_STATUS.PENDING) {
    toasterMessage = "Alert updated — we'll let you know when it's active.";
  } else if (shouldActivate) {
    toasterMessage = "Alert pending — we'll let you know when it's active.";
  } else {
    toasterMessage = "Alert deactivated.";
  }

  const alerts = (await fetchWithAuth.post("/alerts", body)).data;
  setAlerts(alerts);
  setIsSideSheetVisible(false);

  toaster.success(toasterMessage);

  if (window.analytics) {
    window.analytics.track("Alert saved", {
      name: alertName,
      alertId,
      previousStatus: status,
      toasterMessage
    });
  }
};

const AlertSideSheet = ({
  setAlerts,
  alertName,
  alertId,
  status,
  description,
  isSideSheetVisible,
  setIsSideSheetVisible,
  audience,
  setAudience,
  trigger,
  setTrigger,
  triggerConditionType,
  setTriggerConditionType,
  triggerConditionEvent,
  setTriggerConditionEvent,
  triggerConditionPage,
  setTriggerConditionPage,
  triggerConditionWaitDuration,
  setTriggerConditionWaitDuration,
  triggerConditionWaitDurationUnit,
  setTriggerConditionWaitDurationUnit,
  frequency,
  setFrequency,
  location,
  setLocation,
  frequencyTime,
  setFrequencyTime,
  frequencyTimeAMPM,
  setFrequencyTimeAMPM,
  frequencyDay,
  setFrequencyDay,
  sortedEvents,
  sortedPages
}) => {
  const submitAlert = shouldActivate => {
    postAlert(shouldActivate, setAlerts, setIsSideSheetVisible, {
      audience,
      trigger,
      triggerConditionType,
      triggerConditionEvent,
      triggerConditionPage,
      triggerConditionWaitDuration,
      triggerConditionWaitDurationUnit,
      frequency,
      frequencyTime,
      frequencyTimeAMPM,
      frequencyDay,
      location,
      description,
      alertName,
      alertId,
      status
    });
  };
  return (
    <SideSheet
      isShown={isSideSheetVisible}
      onCloseComplete={() => setIsSideSheetVisible(false)}
    >
      <StyledInnerSideSheet>
        <h2>{alertName}</h2>

        <Pane>
          <h3>Who should we monitor?</h3>
          <WhoSegmentedControl
            options={[
              { label: "All users", value: "all" },
              { label: "Custom segment", value: "custom" },
              { label: "Specific users", value: "specific" }
            ]}
            value={audience}
            onChange={value => setAudience(value)}
          />
        </Pane>

        <Pane>
          <h3>What should we tell you about?</h3>
          <WhatSegmentedControl
            options={[
              { label: "Any product activity", value: "any" },
              { label: "Specific activity...", value: "specific" },
              { label: "Lack of activity...", value: "lack" },
              { label: "Unusual activity...", value: "anomaly" }
            ]}
            value={trigger}
            onChange={value => {
              if (value === "lack" && frequency === "immediately") {
                setFrequency("daily");
              }
              setTrigger(value);
            }}
          />
          {["specific", "lack", "anomaly"].includes(trigger) && (
            <Subpane>
              <Radio
                name="eventType"
                onClick={e => setTriggerConditionType(EVENT_TYPES.PAGE)}
                label={`When views on this page
                    ${
                      trigger === "anomaly"
                        ? "have an anomaly"
                        : trigger === "lack"
                        ? "don't happen"
                        : "happen"
                    }`}
                {...(triggerConditionType === EVENT_TYPES.PAGE && {
                  checked: true
                })}
              />
              {triggerConditionType === EVENT_TYPES.PAGE && (
                <Row>
                  <Pane>
                    {/* <h4> */}
                    {/*   When this event{" "} */}
                    {/*   {trigger === "anomaly" */}
                    {/*     ? "has an anomaly" */}
                    {/*     : trigger === "lack" */}
                    {/*     ? "doesn't happen" */}
                    {/*     : "happens"} */}
                    {/* </h4> */}
                    {!sortedPages && "Loading..."}
                    {sortedPages && (
                      <Combobox
                        items={sortedPages.map(({ event }) => event)}
                        selectedItem={triggerConditionPage || ""}
                        onChange={selected => setTriggerConditionPage(selected)}
                        placeholder="Any Page"
                        autocompleteProps={{
                          title: "Pages by frequency"
                        }}
                      />
                    )}
                  </Pane>
                  {trigger === "lack" && (
                    <Pane marginLeft={20}>
                      <h5>For</h5>
                      <Row>
                        <TextInput
                          value={triggerConditionWaitDuration}
                          onChange={e =>
                            setTriggerConditionWaitDuration(e.target.value)
                          }
                          placeholder={1}
                          type="number"
                          width="auto"
                        />
                        <Select
                          onChange={event =>
                            setTriggerConditionWaitDurationUnit(
                              event.target.value
                            )
                          }
                          marginLeft={8}
                        >
                          {[
                            { value: "days", label: "Days" },
                            { value: "weeks", label: "Weeks" },
                            { value: "months", label: "Months" }
                          ].map(item => (
                            <option
                              value={item.value}
                              {...(triggerConditionWaitDurationUnit ===
                                item.value && {
                                selected: true
                              })}
                            >
                              {item.label}
                            </option>
                          ))}
                        </Select>
                      </Row>
                    </Pane>
                  )}
                </Row>
              )}
              <Radio
                name="eventType"
                onClick={e => setTriggerConditionType(EVENT_TYPES.EVENT)}
                label={`When this event
                    ${
                      trigger === "anomaly"
                        ? "has an anomaly"
                        : trigger === "lack"
                        ? "doesn't happen"
                        : "happens"
                    }`}
                {...(triggerConditionType === EVENT_TYPES.EVENT && {
                  checked: true
                })}
              />
              {triggerConditionType === EVENT_TYPES.EVENT && (
                <Row>
                  <Pane>
                    {!sortedEvents && "Loading..."}
                    {sortedEvents && (
                      <Combobox
                        items={sortedEvents.map(({ event }) => event)}
                        selectedItem={triggerConditionEvent || ""}
                        onChange={selected =>
                          setTriggerConditionEvent(selected)
                        }
                        placeholder="Events"
                        autocompleteProps={{
                          title: "Events by frequency"
                        }}
                      />
                    )}
                  </Pane>
                  {trigger === "lack" && (
                    <Pane marginLeft={20}>
                      <h5>For</h5>
                      <Row>
                        <TextInput
                          value={triggerConditionWaitDuration}
                          onChange={e =>
                            setTriggerConditionWaitDuration(e.target.value)
                          }
                          placeholder={1}
                          type="number"
                          width="auto"
                        />
                        <Select
                          onChange={event =>
                            setTriggerConditionWaitDurationUnit(
                              event.target.value
                            )
                          }
                          marginLeft={8}
                        >
                          {[
                            { value: "days", label: "Days" },
                            { value: "weeks", label: "Weeks" },
                            { value: "months", label: "Months" }
                          ].map(item => (
                            <option
                              value={item.value}
                              {...(triggerConditionWaitDurationUnit ===
                                item.value && {
                                selected: true
                              })}
                            >
                              {item.label}
                            </option>
                          ))}
                        </Select>
                      </Row>
                    </Pane>
                  )}
                </Row>
              )}
            </Subpane>
          )}
        </Pane>

        <Pane>
          <h3>How frequently should we tell you?</h3>

          <FrequencySegmentedControl
            options={[
              { label: "Immediately...", value: "immediately" },
              { label: "Daily...", value: "daily" },
              { label: "Weekly...", value: "weekly" }
            ]}
            value={frequency}
            onChange={value => setFrequency(value)}
            trigger={trigger}
          />

          {["weekly"].includes(frequency) && (
            <Subpane>
              <h4>On which day?</h4>
              <SegmentedControl
                flexGrow={1}
                options={[
                  {
                    label: "Mon",
                    value: "Mon"
                  },
                  {
                    label: "Tue",
                    value: "Tue"
                  },
                  {
                    label: "Wed",
                    value: "Wed"
                  },
                  {
                    label: "Thu",
                    value: "Thu"
                  },
                  {
                    label: "Fri",
                    value: "Fri"
                  },
                  {
                    label: "Sat",
                    value: "Sat"
                  },
                  {
                    label: "Sun",
                    value: "Sun"
                  }
                ]}
                value={frequencyDay}
                onChange={value => setFrequencyDay(value)}
              />
            </Subpane>
          )}

          {["daily", "weekly"].includes(frequency) && (
            <Subpane>
              <h4>At what time?</h4>
              <Row>
                <SegmentedControl
                  flexGrow={1}
                  options={Array.from(Array(12)).map((x, i) => ({
                    label: i + 1,
                    value: i + 1
                  }))}
                  value={frequencyTime}
                  onChange={value => setFrequencyTime(value)}
                />
                <SegmentedControl
                  minWidth={100}
                  marginLeft={20}
                  options={[
                    { label: "AM", value: "AM" },
                    { label: "PM", value: "PM" }
                  ]}
                  value={frequencyTimeAMPM}
                  onChange={value => setFrequencyTimeAMPM(value)}
                />
              </Row>
            </Subpane>
          )}
        </Pane>

        <Pane marginBottom={20}>
          <h3>Where should we tell you?</h3>
          <WhereSegmentedControl
            options={[
              { label: "Slack", value: "slack" },
              { label: "Email", value: "email" }
            ]}
            value={location}
            onChange={value => setLocation(value)}
          />
        </Pane>

        <Row marginTop="auto" alignSelf="flex-start">
          <Button
            appearance="primary"
            height={40}
            onClick={e => submitAlert(true)}
          >
            {[ALERT_STATUS.PENDING, ALERT_STATUS.ACTIVE].includes(status)
              ? "Update"
              : "Activate"}
          </Button>

          {[ALERT_STATUS.PENDING, ALERT_STATUS.ACTIVE].includes(status) && (
            <Button
              height={40}
              marginLeft={12}
              onClick={e => submitAlert(false)}
            >
              Deactivate
            </Button>
          )}
        </Row>
      </StyledInnerSideSheet>
    </SideSheet>
  );
};

// Disables "immediately" option if "lack of event" type is selected
const FrequencySegmentedControl = styled(SegmentedControl)`
  & > div:first-child {
    ${props =>
      props.trigger === "lack" &&
      `opacity: 0.5;
        background-image: none;
        background-color: rgba(67, 90, 111, 0.06);
        box-shadow: none;
        color: rgba(67, 90, 111, 0.7);
        pointer-events: none;`}
  }
`;

const WhoSegmentedControl = styled(SegmentedControl)`
  & > div:not(:first-child) {
    opacity: 0.5;
    background-image: none;
    background-color: rgba(67, 90, 111, 0.06);
    box-shadow: none;
    color: rgba(67, 90, 111, 0.7);
    pointer-events: none;
  }
`;

const WhereSegmentedControl = styled(SegmentedControl)`
  & > div:not(:first-child) {
    opacity: 0.5;
    background-image: none;
    background-color: rgba(67, 90, 111, 0.06);
    box-shadow: none;
    color: rgba(67, 90, 111, 0.7);
    pointer-events: none;
  }
`;

const WhatSegmentedControl = styled(SegmentedControl)`
  & > div:last-child {
    opacity: 0.5;
    background-image: none;
    background-color: rgba(67, 90, 111, 0.06);
    box-shadow: none;
    color: rgba(67, 90, 111, 0.7);
    pointer-events: none;
  }
`;

const StyledInnerSideSheet = styled(Pane)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 40px;
  color: #374151;

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-top: 36px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 12px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 12px;
  }

  h5 {
    font-size: 12px;
    margin-bottom: 9px;
    margin-top: -24px;
    font-weight: 400;
  }
`;

const Subpane = styled(Pane)`
  border-left: 4px solid rgba(16, 112, 202, 0.09);
  padding-left: 14px;
  margin-top: 14px;
`;

export default AlertSideSheet;
