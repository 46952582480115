import React from "react";
import { Pane } from "evergreen-ui";
import { FeatureType, FeatureComparison } from "../../../constants/feature";
import { Valence } from "../../../constants/healthscore";

const HealthScoreExplanation = ({
  healthScoreExplanation,
  featureMetadata
}) => {
  const { valence, value } = healthScoreExplanation;
  const { label, type, comparison, transformValue } = featureMetadata;
  return (
    <>
      <Pane is="span" fontWeight={500}>
        {featureLabel({ type, label, valence })}
      </Pane>{" "}
      {comparison !== FeatureComparison.None &&
        `${featureVerb({ comparison, valence })} ${transformValue(value)}`}
    </>
  );
};

function featureLabel({ valence, label, type }) {
  if (type === FeatureType.Boolean && valence === Valence.Bad) {
    return `Not ${label}`;
  }

  return `${label.charAt(0).toUpperCase()}${label.slice(1)}`;
}

function featureVerb({ comparison, valence }) {
  switch (comparison) {
    case FeatureComparison.Equality:
      return valence === Valence.Good ? "is" : "is not";
    case FeatureComparison.Trend:
      return valence === Valence.Good ? "increased by" : "decreased by";
    default:
      return "";
  }
}

export default HealthScoreExplanation;
