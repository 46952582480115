export const CustomerId = {
  Appcues: "appcues",
  H1: "h1",
  Shipbob: "shipbob",
  Upflow: "upflow",
  Quantifai: "quantifai",
  Instabug: "instabug",
  Eventgeek: "eventgeek",
  Ross: "ross"
};

export const CustomerDomainByCustomerId = {
  [CustomerId.Appcues]: "appcues.com",
  [CustomerId.H1]: "h1insights.com",
  [CustomerId.Shipbob]: "shipbob.com",
  [CustomerId.Upflow]: "upflow.io",
  [CustomerId.Quantifai]: "quantif.ai",
  [CustomerId.Instabug]: "instabug.com",
  [CustomerId.Eventgeek]: "eventgeek.com",
  [CustomerId.Ross]: "rossintelligence.com"
};

export const domainToCustomerId = Object.keys(CustomerDomainByCustomerId).reduce(
  (sum, curr) => ({ ...sum, [CustomerDomainByCustomerId[curr]]: curr }),
  {}
);

export const customerSettings = {
  LOGO_WIDTH: {
    "rossintelligence.com": 48,
    "instabug.com": 52
  },
  LOGO_MARGIN: {
    "rossintelligence.com": 6
  },
  hasMutedEvents: {
    "rossintelligence.com": true
  }
};

export const customerEventMappings = {
  "rossintelligence.com": {
    "query-success": {
      label: "Asked question",
      icon: "search"
    },
    "document-open": {
      label: "Document opened",
      icon: "document-open"
    },
    "query-success-good-nl": {
      label: "Asked question (Good NL)",
      icon: "small-tick",
      type: "strong"
    },
    "document-generate-targeted-overview": {
      label: "Document opened",
      icon: "document-open"
    },
    "similar-lang-query-success": {
      label: "Asked question (similar language)",
      icon: "search"
    },
    "document-print": {
      label: "Print document",
      icon: "print"
    },
    "email-case": {
      label: "Email case",
      icon: "envelope"
    },
    "case-saved": {
      label: "Case saved",
      icon: "floppy-disk"
    },
    "print-case": {
      label: "Print case",
      icon: "print"
    },
    "cite-case": {
      label: "Cite case",
      icon: "add"
    },
    "upload-brief": {
      label: "Upload brief",
      icon: "cloud-upload"
    },
    "user-sign-up-success": {
      label: "User sign up success",
      icon: "user"
    },
    "document-analyzer-upload": {
      label: "Document analyzer upload",
      icon: "cloud-upload"
    },
    "user-sign-up-failed-v2": {
      label: "User sign up failed (v2)",
      icon: "error"
    },
    "ssr-trial-start": {
      label: "SSR trial start",
      icon: "star"
    },
    "printed-case": {
      label: "Printed case",
      icon: "print"
    },
    "user-sign-up-failed": {
      label: "User sign up failed",
      icon: "error"
    },
    "doc-analyzer-failed": {
      label: "Document analyzer failed",
      icon: "error"
    },
    "get-case": {
      label: "Case opened",
      icon: "document-open"
    },
    "tutorial-onboarding-slide-view-started": {
      label: "Onboarding tutorial started",
      icon: "learning"
    },
    "user-login": {
      label: "Logged in",
      icon: "log-in"
    },
    "navigate-to-ai-search": {
      label: "Opened AI Search",
      icon: "search"
    },
    "user-logout": {
      label: "Logged out",
      icon: "log-out"
    },
    "view-similar-cases": {
      label: "Viewed similar cases",
      icon: "duplicate"
    },
    registration: {
      label: "Signed up",
      icon: "person"
    },
    "open-folder": {
      label: "Folder opened",
      icon: "folder-open"
    },
    "document-print": {
      label: "Document printed",
      icon: "print"
    },
    "user-verification-success-v2": {
      label: "User verification succeeded",
      icon: "person"
    },
    "history-result-open": {
      label: "History result opened",
      icon: "document-open"
    },
    "user-join-newsletter": {
      label: "Newsletter joined",
      icon: "envelope"
    },
    "document-hyperlink-opened": {
      label: "Document hyperlink opened",
      icon: "link"
    },
    "user-successful-payment": {
      label: "Payment successful",
      icon: "dollar",
      type: "strong"
    }
  }
};
